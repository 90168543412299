import { getAbstractListByListId } from "../../converters/invariants/getAbstractListFromListId";
import { parseValueForHTML } from "./parseValueForHTML";

/**
 * @param {ListHandlerInstance} listHandler
 * @param {string} listItemKey
 * @param {string} listPrefix
 * @param {string} listLevelPattern
 * @param {number} value
 * @param {string} listType
 */
export function getFormattedListStyleType(
  listHandler,
  listItemKey,
  listPrefix,
  listLevelPattern,
  value,
  listType = "number",
  styleName = ""
) {
  if (listType !== "number") return `"${listPrefix} "`;

  const listItem = listHandler.root.find((list) => list.id === listItemKey);
  if (!listItem) {
    throw new Error(`Could not find list item with key ${listItemKey}.`);
  }

  const index = listHandler.root.indexOf(listItem);
  const searchArray = listHandler.root.slice(0, index).reverse();
  let level = listItem.level;

  const abstractList = getAbstractListByListId(
    listHandler.currentSfdt?.lists,
    listHandler.lists,
    listItem.listId
  );

  /** @type {PseudoListItem | undefined} */
  let listItemPointer = listItem;
  let listItemPrefix = listPrefix;

  // Goes until -1 to address level 0.
  while (level >= -1) {
    const levelPattern = /** @type {MarkerPattern} */ (
      abstractList?.levels[listItemPointer?.level ?? -1]?.listLevelPattern
    );

    const calculation = parseValueForHTML(
      listItemPointer?.value ?? 1,
      levelPattern
    );

    if (listItemPointer) {
      const currentLevel = `%${listItemPointer?.level + 1}`;
      listItemPrefix = listItemPrefix.replace(currentLevel, calculation);
    }
    // else {
    //   // Else we default to 1.
    //   const currentIndent = `%${level + 1}`;
    //   markerCopy = markerCopy.replace(currentIndent, calc);
    // }

    // Has parent (priority) or item in level.
    const nextListItem = searchArray.find(
      // eslint-disable-next-line no-loop-func
      (element) => element.id === listItemPointer?.parentId
    );
    if (!nextListItem) break;

    listItemPointer = nextListItem;
    level--;
  }

  // Replace values that were left out of the loop with "1" because we could not find a match.
  listItemPrefix = listItemPrefix.replace(/%(\d)/gm, "1");

  return `"${listItemPrefix} "`;
}
