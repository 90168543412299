import {
  faCaretDown,
  faClone,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashList,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChipButton } from "../../";
import useVersionService from "../../../hooks/useVersionService";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import DialogConfigureTemplate from "../../dialogs/DialogConfigureTemplate";
import DialogConfirmDelete from "../../dialogs/DialogConfirmDelete";
import { APPLY_FILTER_COMMAND } from "../commands";
import { hasRedline } from "../utils";

/**
 * @typedef {*} TemplateVersionPluginProps
 */

/**
 * @param {TemplateVersionPluginProps} props
 * @returns {React.JSX.Element}
 */
export default function TemplateVersionPlugin(props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const taskType = searchParams.get("taskType");
  const taskId = searchParams.get("taskId");

  const [anchorElTemplateLevel, setAnchorElTemplateLevel] = useState(null);
  const [alertDialogType, setAlertDialogType] = useState(null);
  const [
    // eslint-disable-next-line no-unused-vars
    loading,
    setLoading,
  ] = useState(false);
  const [openDialogConfigureTemplate, setOpenDialogConfigureTemplate] =
    useState(() => {
      if ((taskType === "Review" || taskType === "Approval") && taskId) {
        return true;
      }

      return false;
    });

  const navigate = useNavigate();
  const { duplicateVersion, deleteVersion } = useVersionService();

  useEffect(() => {}, []);

  /**
   * @param {*} event
   */
  const handleOpenTemplateLevelMenu = (event) => {
    setAnchorElTemplateLevel(event.currentTarget);
  };

  const handleCloseTemplateLevelMenu = () => {
    setAnchorElTemplateLevel(null);
  };

  /**
   * @param {*} isConfirmed
   */
  const alertConfirm = (isConfirmed) => {
    handleTemplateAction(alertDialogType, isConfirmed);
  };

  /**
   * @param {*} action
   * @param {*} value
   */
  const handleTemplateAction = async (action, value) => {
    setLoading(true);
    setAnchorElTemplateLevel(null);
    let creationDate = new Date().toISOString();

    if (["viewDraft"].includes(action)) {
      let latestDraft = state.templates
        .filter(
          (/** @type {*} */ t) =>
            !t.active && t.blueprintID === props.template.blueprintID
        )
        .sort((/** @type {*} */ a, /** @type {*} */ b) =>
          a.version > b.version ? -1 : 1
        )[0];

      if (Boolean(latestDraft) && latestDraft._id !== props.template._id) {
        navigate("/templates/" + latestDraft._id);
      } else {
        setLoading(false);
      }
    } else if (["deactivateVersion"].includes(action)) {
      let templateToDeactivate = props.template;
      templateToDeactivate.lastUpdateBy = state.user._id;
      templateToDeactivate.lastUpdateDate = creationDate;
      templateToDeactivate.active = false;
      pushUpdateTemplateVersions([templateToDeactivate]);
    } else if (["activateVersion"].includes(action)) {
      let templatesToUpdate = [];
      let hasOpenIssues = false;

      editor.update(() => {
        const nodeMapArray = [...editor.getEditorState()._nodeMap];
        hasOpenIssues = nodeMapArray
          .filter((keyPlusNode) =>
            ["clause"].includes(keyPlusNode[1].getType())
          )
          .some((keyPlusNode) => hasRedline(keyPlusNode[1]));
      });

      if (hasOpenIssues) {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "Please resolve all open issues before activating.",
            severity: "warning",
          },
        });
        //setSnackOpen("Please resolve all open issues before activating.")
        editor.dispatchCommand(APPLY_FILTER_COMMAND, "openIssues");
      } else {
        // Activate this template
        let newlyActiveTemplate = props.template;
        newlyActiveTemplate.lastUpdateBy = state.user._id;
        newlyActiveTemplate.lastUpdateDate = creationDate;
        newlyActiveTemplate.active = true;
        templatesToUpdate.push(newlyActiveTemplate);

        pushUpdateTemplateVersions(templatesToUpdate);
      }
    } else if (["copyVersion"].includes(action)) {
      const version = state.drawerVersions.active;

      await duplicateVersion(version._id, {}, true);
    } else if (["deleteVersion"].includes(action) && Boolean(value)) {
      const version = state.drawerVersions.active;
      await deleteVersion(version._id, true);

      setAlertDialogType(null);
    } else if (["deleteTemplate"].includes(action) && Boolean(value)) {
      // Confirmed delete template - ie. all versions
      pushDeleteTemplateVersions(state.template);
    } else if (
      ["deleteVersion", "deleteTemplate"].includes(action) &&
      value === null
    ) {
      setLoading(false);
      setAlertDialogType(action);
    } else if (action === "configureTemplate") {
      setOpenDialogConfigureTemplate(true);
    } else {
      // Fallback scenario - clear everything
      setAlertDialogType(null);
      setLoading(false);
    }
  };

  /**
   * @param {*} versionsToUpdate
   */
  const pushUpdateTemplateVersions = (versionsToUpdate) => {
    let updatedTemplates = [];

    // Execute the updates
    versionsToUpdate.forEach(
      (/** @type {*} */ ttu, /** @type {number} */ i) => {
        // Ignore changes to to roles. Prevents issue where roles are empty and
        // clean existing roles.
        delete ttu.roles;
        axios
          .put(state.settings.api + "template/" + ttu._id, { template: ttu })
          .then((resTemp) => {
            if (resTemp.data.success) {
              // Add updated template to the reducer
              updatedTemplates.push(resTemp.data.data);
              dispatch({
                type: "UPDATE_TEMPLATES",
                payload: resTemp.data.data,
              });
              if (versionsToUpdate.length === updatedTemplates.length) {
                dispatch({
                  type: "NEW_SNACKBAR",
                  payload: {
                    message: "The template was successfully updated",
                    severity: "success",
                  },
                });
                setLoading(false);
              }
            } else {
              dispatch({
                type: "NEW_SNACKBAR",
                payload: {
                  message:
                    "An error occured while updating this template - refresh your browser",
                  severity: "error",
                },
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "An error occured while updating this template",
                severity: "error",
              },
            });
            setLoading(false);
          });
      }
    );
  };

  /**
   * @param {*} versionsToDelete
   */
  const pushDeleteTemplateVersions = (versionsToDelete) => {
    let creationDate = new Date().toISOString();
    let versionsDeleted = [];

    versionsToDelete.forEach((/** @type {*} */ template) => {
      template.active = false;
      template.lastUpdateBy = state.user._id;
      template.lastUpdateDate = creationDate;
      template.orgID = "deleted_" + template.orgID;
      template.blueprintID = "deleted_" + template.blueprintID;
      template.deleted = true;

      axios
        .delete(state.settings.api + "template/" + template._id)
        .then((resTemp) => {
          if (resTemp.data.success) {
            // Add updated template to the reducer
            dispatch({ type: "UPDATE_TEMPLATES", payload: resTemp.data.data });
            versionsDeleted.push(resTemp.data.data);
            if (versionsDeleted.length === versionsToDelete.length) {
              navigate("/templates/main");
              setLoading(false);
              dispatch({
                type: "NEW_SNACKBAR",
                payload: {
                  message:
                    "Template version" +
                    (versionsToDelete.length > 1 ? "s" : "") +
                    " succesfully deleted",
                  severity: "success",
                },
              });
            }
          } else {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message:
                  "An error occured while deleting the version - refresh your browser",
                severity: "error",
              },
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "An error occured while deleting the version",
              severity: "error",
            },
          });
          setLoading(false);
        });
    });
  };

  const getDialogMessage = () => {
    if (alertDialogType === "deleteVersion") {
      return "Are you sure you want to delete this template version?";
    }

    if (alertDialogType === "deleteTemplate") {
      return (
        <>
          Are you sure you want to delete this{" "}
          <span style={{ color: theme.palette.error.main, fontWeight: "700" }}>
            entire template
          </span>
          ?
        </>
      );
    }
  };

  const getDialogTitle = () => {
    if (alertDialogType === "deleteVersion") return "Delete Version";

    if (alertDialogType === "deleteTemplate") return "Delete Template";
  };

  return (
    <div>
      <Box>
        <ChipButton
          icon={faCaretDown}
          iconPos="right"
          text={`v${props.template.version}${
            props.template.active ? "" : " (draft)"
          }`}
          color="primary"
          variant={props.template.active ? "contained" : "outlined"}
          // @ts-ignore
          buttonClick={handleOpenTemplateLevelMenu}
        />

        {/*
        <Chip 
        label={<>
            v{parseInt(props.template.version)}
            <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: '3px'}} />
        </>}
        variant={Boolean(props.template) && Boolean(props.template.active) ? "contained" : "outlined"}
        color="primary" 
        size="small"
        sx={{fontWeight: '700', padding: '3px', height: '32px', border: Boolean(props.template) && Boolean(props.template.active) ? '0px' : '1px solid' + theme.palette.grey[300] }} 
        onClick={handleOpenTemplateLevelMenu}
        />*/}
      </Box>

      <Menu
        sx={{ mt: "40px", ml: "0px" }}
        id="menu-appbar-template"
        anchorEl={anchorElTemplateLevel}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        open={Boolean(anchorElTemplateLevel)}
        onClose={handleCloseTemplateLevelMenu}
      >
        {[
          ...(Boolean(props.template) &&
          Boolean(props.template.active) &&
          state.templates.some(
            (/** @type {*} */ t) =>
              t.blueprintID === props.template.blueprintID && !t.active
          )
            ? []
            : []),
          // Formerly was the below (all of his will be deleted once new template management is in place)
          // ? [{ id: "viewDraft", name: "View latest Draft", icon: faFilePen }]
          // : []),
          //{ id: 'viewAll', name: "View all versions", icon: faList },
          ...(Boolean(props.template) && Boolean(props.template.active)
            ? [
                {
                  id: "deactivateVersion",
                  name: "Deactivate this version",
                  icon: faToggleOff,
                },
              ]
            : [
                {
                  id: "activateVersion",
                  name: "Activate this version",
                  icon: faToggleOn,
                },
              ]),
          // { id: "copyVersion", name: "Copy into new version", icon: faClone },
          ...(Boolean(props.template) && Boolean(props.template.active)
            ? []
            : [
                // {
                //   id: "deleteVersion",
                //   name: "Delete this version",
                //   icon: faTrash,
                // },
              ]),
          {
            id: "deleteTemplate",
            name: "Delete this template",
            icon: faTrashList,
          },
          // {
          //   id: "configureTemplate",
          //   name: "Configure template",
          //   icon: faGear,
          //   // disabled: props?.template?.active,
          //   disabled: false,
          // },
        ].map((item, index) => (
          <MenuItem
            key={index}
            onClick={(_event) => handleTemplateAction(item.id, null)}
            style={{ width: "245px" }}
            // disabled={item.disabled}
          >
            <ListItemIcon>
              {item.icon ? <FontAwesomeIcon icon={item.icon} /> : ""}
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <DialogConfirmDelete
        open={!!alertDialogType}
        title={getDialogTitle()}
        message={getDialogMessage()}
        handleClose={() => setAlertDialogType(null)}
        handleConfirm={() => alertConfirm(true)}
      />

      {openDialogConfigureTemplate && (
        <DialogConfigureTemplate
          open={openDialogConfigureTemplate}
          close={() => {
            setOpenDialogConfigureTemplate(false);
            setSearchParams({});
          }}
          template={props.template}
          taskType={taskType}
          taskId={taskId}
        />
      )}
    </div>
  );
}
