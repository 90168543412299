import axios from "axios";
import Pusher from "pusher-js";

// Enable logging for debugging.
if (process.env.NODE_ENV !== "production") {
  Pusher.logToConsole = true;
}

/**
 * @type {Pusher | null}
 */
let pusherInstance = null;

/**
 * @param {string} url
 * @returns {Promise<{ pusherKey: string; pusherCluster: string; }>}
 */
async function getPusherAppKey(url) {
  try {
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.error("Error retrieving Pusher app key:", error);
    throw error;
  }
}

/**
 * @param {string} url
 * @returns {Promise<Pusher>}
 */
export async function getPusher(url) {
  if (!pusherInstance) {
    const pusherMetadata = await getPusherAppKey(url);
    const isProd = process.env.NODE_ENV === "production";

    pusherInstance = new Pusher(pusherMetadata.pusherKey, {
      cluster: pusherMetadata.pusherCluster,
      forceTLS: isProd,
    });
  }
  return pusherInstance;
}
