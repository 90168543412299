import { $dfs } from "@lexical/utils";
import { GlobalListHandler } from "./GlobalListHandler";
import { getListItemsFromDfs } from "./getListItemsFromDfs";
import { $populateListHandler } from "./populateListHandler";

/**
 * Populates the `GlobalListHandler` auxiliary data structure to help with logic regarding lists.
 *
 * @param {import("lexical").LexicalEditor} editor
 * @param {import("../../types/sfdt").Sfdt} sfdt
 */
export function populateGlobalListHandler(editor, sfdt) {
  const editorState = editor.getEditorState();
  if (!editorState) return;

  const { lists, root } = editor.getEditorState().read(() => {
    const listItems = getListItemsFromDfs($dfs());

    // console.log(JSON.parse(JSON.stringify(sfdt)));
    // console.log(JSON.parse(JSON.stringify(listItems)));

    const result = $populateListHandler(
      listItems,
      sfdt.lists,
      sfdt.abstractLists
    );

    // console.log(JSON.parse(JSON.stringify(result)));

    return result;
  });

  const listHandler = GlobalListHandler.getInstance();
  listHandler.currentSfdt = sfdt;
  listHandler.lists = lists;
  listHandler.root = root;

  return listHandler;
}
