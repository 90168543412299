import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";
import PartyManagementSection from "./SendComponents/PartyManagementSection";

/**
 * @typedef {object} DialogManagePartiesProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {*} owner
 * @property {*} handlePartyChange
 * @property {*} roles
 * @property {*} setRoles
 * @property {*} setAgreementUpdate
 * @property {() => void} submit
 */

/**
 * @param {DialogManagePartiesProps} props
 * @returns {JSX.Element}
 */
export function DialogManageParties({
  open,
  close,
  owner,
  handlePartyChange,
  roles,
  setRoles,
  setAgreementUpdate,
  submit,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Manage Parties</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <PartyManagementSection
          roles={roles}
          setRoles={setRoles}
          owner={owner}
          handlePartyChange={handlePartyChange}
          setAgreementUpdate={setAgreementUpdate}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        {state?.sendFlow?.removeParty ? (
          <>
            <Button
              onClick={() =>
                dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: null })
              }
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() =>
                dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_PARTY" })
              }
            >
              Confirm
            </Button>
          </>
        ) : state?.sendFlow?.addParty ? (
          <>
            <Button
              onClick={() =>
                dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: false })
              }
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => dispatch({ type: "SEND_FLOW_SUBMIT_ADD_PARTY" })}
            >
              Confirm
            </Button>
          </>
        ) : (
          <>
            <Button onClick={close}>Cancel</Button>

            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={submit}
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
