import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { globalStore } from "../../state/store";

/**
 * @typedef {object} AgreementParty
 * @property {object} sourceData
 * @property {string} sourceData.source
 * @property {string} _id
 * @property {string} orgID
 * @property {null} ownerOrgID
 * @property {string} entityCategory
 * @property {string} shortName
 * @property {string} legalName
 * @property {object[]} address
 * @property {string} address.addressType
 * @property {string} address.streetAndNo
 * @property {string} address.address2
 * @property {string} address.zip
 * @property {string} address.city
 * @property {string} address.state
 * @property {string} address.country
 * @property {string} address._id
 * @property {string} logoURL
 * @property {boolean} active
 * @property {object[]} defaultSigners
 * @property {string} defaultSigners._id
 * @property {string} defaultSigners.email
 * @property {string} defaultSigners.password
 * @property {string} defaultSigners.firstName
 * @property {string} defaultSigners.lastName
 * @property {string} defaultSigners.displayName
 * @property {string} defaultSigners.title
 * @property {string} defaultSigners.orgID
 * @property {string} defaultSigners.photoURL
 * @property {string} defaultSigners.role
 * @property {boolean} defaultSigners.active
 * @property {string} defaultSigners.createdAt
 * @property {string} defaultSigners.updatedAt
 * @property {number} defaultSigners.__v
 * @property {string} creationBy
 * @property {boolean} defaultLegalEntity
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 * @property {string} role
 * @property {string} partyID
 * @property {boolean} myClient
 */

/**
 * @typedef {object} TrackedChangesAuthorshipProps
 * @property {import("../../views/CreateAgreement").AgreementData} agreement
 * @property {(type: "trackedChangesAuthorship", value: AgreementParty | null) => void} handleAgreementChange
 */

/**
 * @param {TrackedChangesAuthorshipProps} props
 * @returns {React.JSX.Element}
 */
export default function TrackedChangesAuthorship({
  agreement,
  handleAgreementChange,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  useEffect(
    () => {
      if (agreement.parties.length === 0) return;

      if (agreement.parties.length === 1) {
        handleAgreementChange("trackedChangesAuthorship", agreement.parties[0]);
      } else {
        const counterparty = agreement.parties.find(
          (x) => x.orgID !== state.user.orgID
        );

        handleAgreementChange(
          "trackedChangesAuthorship",
          // @ts-ignore
          counterparty
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agreement.parties]
  );

  return (
    <Grid container mt={4}>
      <Autocomplete
        options={agreement.parties}
        sx={{ width: 300 }}
        value={agreement.trackedChangesAuthorship}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tracked Changes Author"
            placeholder="Select party ..."
            required={true}
          />
        )}
        getOptionLabel={(option) => option.legalName}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img loading="lazy" width="20" src={option.logoURL} alt="" />
            {option.legalName} ({option.role})
          </Box>
        )}
        onChange={(_event, value) =>
          handleAgreementChange("trackedChangesAuthorship", value)
        }
      />
    </Grid>
  );
}
