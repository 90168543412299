import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import RemindersSection from "./RemindersSection";

export const wordEditAuthorizationOptions = [
  {
    label: "Full Editing",
    value: "fullEditing",
    description: "Can edit the document without tracking changes",
  },
  {
    label: "Tracked Changes",
    value: "trackedChanges",
    description: "Can make tracked changes (but not accept or reject them)",
  },
  {
    label: "Comments",
    value: "comments",
    description: "Can add comments only",
  },
  {
    label: "Read-only",
    value: "readOnly",
    description: "Can read the document only",
  },
];

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
export default function ReadySignSection({
  readyToSign,
  message,
  reminders,
  isOwner,
  handleChangeReady,
  handleChangeMessage,
  handleChangeReminder,
  attachWordDocumentToEmail,
  setAttachWordDocumentToEmail,
  attachWordDocumentToEmailType,
  setAttachWordDocumentToEmailType,
  setAttachPdfFileToEmail,
  attachPdfFileToEmail,
  attachPdfFileToEmailType,
  setAttachPdfFileToEmailType,
  wordEditAuthorization,
  setWordEditAuthorization,
  comparisonVersion,
  setComparisonVersion,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  // Ignore the first version because that's the one we are currently seeing.
  const [, ...versionsOptions] = state.drawerVersions.versions.map(
    (/** @type {*} */ version) => ({
      label: `Version ${version.version}`,
      author: version.creationBy.displayName,
      description: version.description,
      lastUpdated: `${DateTime.fromISO(version.lastUpdateDate).toFormat(
        "dd LLL yyyy"
      )} by ${version.lastUpdateBy.displayName}`,
      version,
    })
  );

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  // const currentOrganization = state.org;
  // const currentParty = state.parties.find(
  //   (/** @type {{ orgID: string; }} */ p) => p.orgID === state.org._id
  // );

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        px: isMdUp ? 6 : isSmUp ? 4 : 0,
        width: "100%",
      }}
    >
      <Grid item sx={{ mt: 2, width: "100%" }} xs={12}>
        <TextField
          id="outlined-textarea"
          placeholder="Add message to counterparty ..."
          multiline
          autoFocus
          rows={4}
          style={{ width: "100%" }}
          value={message}
          onChange={(e) => handleChangeMessage(e.target.value)}
        />
      </Grid>

      {reminders !== null && isOwner && (
        <Grid item sx={{ mt: 2, width: "100%" }} xs={12}>
          <RemindersSection
            tooltip="Automatic email reminders will be sent until the agreement is returned."
            reminders={reminders}
            handleChangeReminder={handleChangeReminder}
          />
        </Grid>
      )}

      {state.user.role.name !== "Counterparty" && (
        <>
          {/* <Grid container mt={2} pl={2}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={readyToSign}
                      onChange={() => handleChangeReady(!readyToSign)}
                    />
                  }
                  label={`Mark as "ready to sign"`}
                />
                <Typography
                  pl={4}
                  variant="body2"
                  color={theme.palette.grey[600]}
                >
                  {`(from ${currentOrganization.shortName}'s perspective)`}
                </Typography>
              </FormGroup>
            </Grid>
          </Grid> */}

          <Grid container mt={2} pl={2}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={attachWordDocumentToEmail}
                      onChange={() =>
                        setAttachWordDocumentToEmail(!attachWordDocumentToEmail)
                      }
                    />
                  }
                  label={`Attach agreement in Word`}
                />

                <Typography
                  pl={4}
                  variant="body2"
                  color={theme.palette.grey[600]}
                >
                  {`(In addition to the agreement link)`}
                </Typography>
              </FormGroup>

              {attachWordDocumentToEmail && (
                <>
                  <Grid pl={4}>
                    <RadioGroup
                      value={attachWordDocumentToEmailType}
                      onChange={(event) =>
                        setAttachWordDocumentToEmailType(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="leaveIn"
                        control={<Radio />}
                        label="Highlight current changes and comments"
                      />

                      <FormControlLabel
                        value="cleanUp"
                        control={<Radio />}
                        label="Clean (changes and comments accepted)"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid pl={4} mt={1}>
                    <Autocomplete
                      value={wordEditAuthorizationOptions.find(
                        (x) => x.value === wordEditAuthorization
                      )}
                      onChange={(_event, value) =>
                        setWordEditAuthorization(value?.value)
                      }
                      options={wordEditAuthorizationOptions}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Word Edit Authorization"
                          placeholder="Protect document for ..."
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="body1"
                                fontWeight="bolder"
                                color={theme.palette.grey[800]}
                              >
                                {option.label}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography
                                variant="subtitle2"
                                color={theme.palette.grey[500]}
                              >
                                {option.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    />
                  </Grid>

                  {/* <Grid
                    pl={3}
                    mt={1}
                    onClick={() => {
                      // TODO: Add preview download.
                    }}
                  >
                    <Button>Preview ...</Button>
                  </Grid> */}
                </>
              )}
            </Grid>
          </Grid>

          <Grid container mt={2} pl={2}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={attachPdfFileToEmail}
                      onChange={() =>
                        setAttachPdfFileToEmail(!attachPdfFileToEmail)
                      }
                    />
                  }
                  label={`Attach agreement in PDF`}
                />

                <Typography
                  pl={4}
                  variant="body2"
                  color={theme.palette.grey[600]}
                >
                  {`(In addition to the agreement link)`}
                </Typography>
              </FormGroup>

              {attachPdfFileToEmail && (
                <>
                  <Grid pl={4}>
                    <RadioGroup
                      value={attachPdfFileToEmailType}
                      onChange={(event) =>
                        setAttachPdfFileToEmailType(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="clean"
                        control={<Radio />}
                        label="Clean (changes and comments accepted)"
                      />

                      {getCanveoTier(state?.user?.email) === "experimental" && (
                        <>
                          <FormControlLabel
                            value="comparison"
                            control={<Radio />}
                            label="Comparison vs. earlier version ..."
                          />

                          {attachPdfFileToEmailType === "comparison" && (
                            <>
                              <Grid mt={1}>
                                <Autocomplete
                                  value={comparisonVersion}
                                  onChange={(_event, value) =>
                                    setComparisonVersion(value)
                                  }
                                  disablePortal
                                  options={versionsOptions}
                                  sx={{ width: 300 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Compare against version ..."
                                      placeholder="Select version"
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <Grid container direction="column">
                                        <Grid item>
                                          <Typography
                                            variant="body1"
                                            fontWeight="bolder"
                                            color={theme.palette.grey[800]}
                                          >
                                            {option.label}
                                          </Typography>
                                        </Grid>

                                        <Grid item>
                                          <Typography
                                            variant="subtitle2"
                                            color={theme.palette.grey[500]}
                                          >
                                            Author: {option.author}
                                          </Typography>
                                        </Grid>

                                        <Grid item>
                                          <Typography
                                            variant="subtitle2"
                                            color={theme.palette.grey[500]}
                                          >
                                            Description: {option.description}
                                          </Typography>
                                        </Grid>

                                        <Grid item>
                                          <Typography
                                            variant="subtitle2"
                                            color={theme.palette.grey[500]}
                                          >
                                            Last updated: {option.lastUpdated}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </RadioGroup>
                  </Grid>

                  {/* <Grid
                    pl={3}
                    mt={1}
                    onClick={() => {
                      // TODO: Add preview download.
                    }}
                  >
                    <Button>Preview ...</Button>
                  </Grid> */}
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
