import {
  faBowArrow,
  faDownload,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import download from "js-file-download";
import {
  default as React,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  AgrExecBox,
  CanveoCircularProgress,
  ExportDocumentDialog,
  Header,
} from ".";
import docx from "../assets/img/docx.png";
import pptx from "../assets/img/pptx.png";
import xlsx from "../assets/img/xlsx.png";
import { useDebouncedValue } from "../hooks/useDebouncedValue";
import useFileService from "../hooks/useFileService";
import useVersionService from "../hooks/useVersionService";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getCanveoTier } from "../utils/getCanveoTier";
import ExportDocumentDialogForCounterparties from "./ExportDocumentDialogForCounterparties";
import LockBox from "./LockBox";
import DialogConfigureTemplate from "./dialogs/DialogConfigureTemplate";
import DialogOwnerSendOptions from "./dialogs/DialogOwnerSendOptions";
import CoreDrawer from "./drawer/CoreDrawer";
import ExhibitsDrawer from "./drawer/ExhibitsDrawer";
import { FastTrackDrawer } from "./drawer/FastTrackDrawer";
import PlaybookDrawer from "./drawer/PlaybookDrawer";
import QuestionnaireDrawer from "./drawer/QuestionnaireDrawer";
import RightCoreDrawer from "./drawer/RightCoreDrawer";
import SummaryDrawer from "./drawer/SummaryDrawer";
import {
  getAgreementDrawerItems,
  getTemplateDrawerItems,
} from "./drawer/getDrawerItems";
import defaultSfdt, { defaultLexical } from "./editor/converters/defaultSfdt";
import { CrossReferenceEventsPlugin } from "./editor/converters/utils/cross_references";
import {
  CanveoPlugin,
  ClausePlugin,
  CopyLexicalContentsToClipboardPlugin,
  DefinitionsPlugin,
  ExportEditorToDocumentPlugin,
  ListMaxIndentLevelPlugin,
  OpenIssuesPlugin,
  TemplateVersionPlugin,
  TocPlugin,
  ToolbarPlugin,
  TrackChangesPlugin,
  TreeViewPlugin,
  WorkflowPlugin,
} from "./editor/plugins";
import ClauseOptionsPlugin from "./editor/plugins/ClauseOptionsPlugin";
import FloatingContextMenuPlugin from "./editor/plugins/FloatingContextMenuPlugin/FloatingContextMenuPlugin";
import ImagesPlugin from "./editor/plugins/ImagesPlugin";
import ListExtendedPlugin from "./editor/plugins/ListExtendedPlugin";
import { GlobalListHandler } from "./editor/plugins/ListExtendedPlugin/GlobalListHandler";
import PlaybookPlugin from "./editor/plugins/PlaybookPlugin";
import { TableMenuPlugin } from "./editor/plugins/TableMenuPlugin";
import VersionsPlugin from "./editor/plugins/VersionsPlugin";
import { editorConfig } from "./editor/utils";
import { EDITOR_WIDTH } from "./editor/utils/constants";

const devMode = process.env.REACT_APP_EDITOR_DEV_MODE === "enabled";

/**
 * @typedef {{ page: "Template" | "Agreement"; template: *; aid: string; }} EditorProps
 */

/**
 * @param {EditorProps} props
 * @returns {React.JSX.Element}
 */
export default function Editor(props) {
  // Used to make sure we do not run into bugs where renders happen infinitely.
  console.debug("EDITOR_INFINITE_RENDER_DEBUG_TOKEN");

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const containerWithScrollRef = useRef(/** @type {* | null} */ (null));
  const exportEditorToDocumentPluginRef = useRef(
    /** @type {* | null} */ (null)
  );
  const copyLexicalJsonToClipboardPluginRef = useRef(
    /** @type {* | null} */ (null)
  );

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);
  const { downloadFile } = useFileService();
  const [, setSearchParams] = useSearchParams();

  const [file, setFile] = useState(/** @type {* | null} */ (null));

  const [openExportDocumentDialog, setOpenExportDocumentDialog] =
    useState(false);
  const [exportDocumentDialogIsLoading, setExportDocumentDialogIsLoading] =
    useState(false);
  const [drawerItems, setDrawerItems] = useState(/** @type {*[]} */ ([]));
  const [exportFullAgreement, setExportFullAgreement] = useState(false);
  const [openDialogConfigureTemplate, setOpenDialogConfigureTemplate] =
    useState(false);
  const [openApprovalsAndReviewsDialog, setOpenApprovalsAndReviewsDialog] =
    useState(false);

  const [defaultLexicalValue, setDefaultLexicalValue] = useState(
    /** @type {* | null} */ (null)
  );

  /** @type {ReturnType<typeof useState<import("lexical").SerializedEditorState<import("lexical").SerializedLexicalNode>>>} */
  const [serializedEditorState, setSerializedEditorState] = useState();
  const debouncedSerializedEditorState = useDebouncedValue(
    serializedEditorState,
    1000
  );

  /**
   * Sometimes, even if we do not allow normal text editing of the Editor, it may be possible to make changes
   * e.g., by updating the value of merge fields or by adding a comment. These changes need to be persisted.
   *
   * @returns {boolean}
   */
  function canPersistEditorChanges() {
    if (readOnly) return false;

    if (
      state?.drawerVersions?.active?._id !==
      state?.drawerVersions?.versions?.at(0)?._id
    ) {
      return false;
    }

    return true;
  }

  useEffect(
    () => {
      if (!debouncedSerializedEditorState) return;
      if (!canPersistEditorChanges()) return;

      saveEditorState(debouncedSerializedEditorState);
    },
    // This useEffect should only run whenever there are changes to debouncedSerializedEditorState.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSerializedEditorState]
  );

  const getDefaultLexical = async () => {
    const lexical = await defaultLexical();

    setDefaultLexicalValue(lexical.content);
  };

  useEffect(
    () => {
      getDefaultLexical();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isCounterparty = state?.user?.role?.name === "Counterparty";

  useEffect(
    () => {
      /** @type {*[]} */
      let drawerItems = [];

      /** @type {{ restrictEditing: boolean, restrictSending: boolean }} */
      const userRole = state.user.role;

      if (props.page === "Template") {
        drawerItems = getTemplateDrawerItems(
          state?.user?.email,
          userRole.restrictEditing
        );
      } else if (props.page === "Agreement") {
        drawerItems = getAgreementDrawerItems({
          email: state?.user?.email,
          hideExport: userRole.restrictEditing,
          isCounterparty,
        });
      }

      setDrawerItems(drawerItems);
    },
    // This useEffect should only be triggered when props.page changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.page]
  );

  const isTemplating = ["Template"].includes(props.page);
  const templateIsActive = isTemplating && props.template.active;
  const docID = isTemplating ? props.template._id : props.aid;

  const mainAg = isTemplating
    ? props.template
    : state.agrs.find((/** @type {{ parentID: string; }} */ a) => !a.parentID);

  const av = useMemo(() => {
    let version;
    if (isTemplating) {
      version = {
        content: mainAg.content,
        contentMetadata: mainAg.contentMetadata,
        sfdt: mainAg.sfdt,
        versionType: "canveo",
        _id: state.drawerVersions.active?._id,
      };
    } else {
      version = state.avs.find(
        (/** @type {{ agrID: string; }} */ av) => av.agrID === props.aid
      );
    }
    return version;
  }, [isTemplating, mainAg, state.avs, props.aid, state.drawerVersions.active]);

  // Set the SFDT on the global list handler so that we can have access to the document styles and character
  // format in the `createDOM` function of every node, even if the current document does not have any lists.
  const listHandler = GlobalListHandler.getInstance();
  listHandler.currentSfdt = av.sfdt;

  const editMode = av.owner?.find(
    (/** @type {{ orgID: string; }} */ organization) =>
      organization.orgID === state.org._id
  )?.editMode;
  const partyRoleIsReadonly = editMode === "read";

  // Agreement scenario.
  const [firstPageHeader] = useState(
    /** @type {import("./editor/types/sfdt").Sfdt}*/ (
      isTemplating ? props.template.firstPageHeader : av.firstPageHeader
    )
  );
  const [firstPageFooter] = useState(
    /** @type {import("./editor/types/sfdt").Sfdt}*/ (
      isTemplating ? props.template.firstPageFooter : av.firstPageFooter
    )
  );

  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  /** @type {boolean} */
  const isCurrentVersionOwner =
    !isAgrExec &&
    (isTemplating ||
      (!isTemplating &&
        Boolean(mainAg) &&
        mainAg.avOwners.some(
          (/** @type {string} */ owner) => owner === state.org._id
        )));

  const isAgreementOwner = isTemplating
    ? true
    : mainAg.owner === state.user.orgID;

  function showEditorToolbarByDefault() {
    if (isTemplating) return true;

    if (["InEffect", "Execution"].includes(mainAg.agrStatus)) return false;

    if (!isCurrentVersionOwner || !isAgreementOwner) return false;

    return true;
  }

  const isNonPDFAttachment =
    !isTemplating && ["docx", "pptx", "xlsx"].includes(av.versionType);

  const partyID = isTemplating
    ? "party0"
    : mainAg.ents.filter((/** @type {{ entID: string; }} */ e) =>
        state.subs.some(
          (/** @type {{ _id: string; }} */ s) => s._id === e.entID
        )
      )[0] !== undefined
    ? mainAg.ents.filter((/** @type {{ entID: string; }} */ e) =>
        state.subs.some(
          (/** @type {{ _id: string; }} */ s) => s._id === e.entID
        )
      )[0].partyID
    : // Possible temporary fix to prevent infinite loading mode if no parties attached to agreement.
      "party0";

  useVersionService(docID, isTemplating);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(
      state.drawerVersions.active?._id !==
        state.drawerVersions.versions[0]?._id ||
        (isTemplating && props.template?.active)
    );
  }, [
    state.drawerVersions.active,
    state.drawerVersions.versions,
    isTemplating,
    props.template?.active,
  ]);

  useEffect(
    () => {
      // Load the PDF file or link to the non-PDF attachment.
      if (
        ["pdf", "docx", "pptx", "xlsx"].includes(av.versionType) &&
        av.content !== undefined &&
        av.content !== null &&
        av.content.file !== undefined
      ) {
        axios
          .get(
            // For PDF: Download to arraybuffer, for others - get a "viewable" temporary URL.
            state.settings.api +
              "download/" +
              (isNonPDFAttachment ? "read/" : "") +
              av.content.file,
            isNonPDFAttachment ? {} : { responseType: "arraybuffer" }
          )
          .then((res) => {
            setFile({
              // @ts-ignore
              type: av.versionType,
              file: isNonPDFAttachment ? res.data.data : res.data,
              pageNumber: 1,
            });
          })
          .catch((err) => console.log("todo err handling", err));
      }
    },
    // Runs only when `av` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [av]
  );

  const handleDownloadFile = () => {
    if (["pdf"].includes(file?.type)) {
      download(file.file, "file." + file.type);
    } else {
      // Non-PDF attachment.
      window.open(file.file, "_blank");
    }
  };

  /**
   * @param {{size: "small" | "medium" | "large"}} _
   */
  const RenderUpDownButtons = ({ size }) => {
    return (
      <>
        <Button
          size={size}
          variant={size === "large" ? "contained" : "text"}
          sx={
            size === "large"
              ? { padding: "5px 20px", fontSize: "17px", margin: "5px" }
              : { padding: "3px 10px" }
          }
          disableElevation
          onClick={handleDownloadFile}
        >
          Download &nbsp;&nbsp;
          <FontAwesomeIcon icon={faDownload} />
        </Button>

        <Button
          size={size}
          variant={size === "large" ? "contained" : "text"}
          sx={
            size === "large"
              ? { padding: "5px 20px", fontSize: "17px", margin: "5px" }
              : { padding: "3px 10px" }
          }
          disableElevation
        >
          Upload &nbsp;&nbsp;
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      </>
    );
  };

  /**
   * @param {import("lexical").EditorState} editorState
   * @param {import("lexical").LexicalEditor} _editor
   * @param {Set<string>} tags
   */
  function onChange(editorState, _editor, tags) {
    // We NEVER want to save changes to the editor if the `onChange` was triggerd by switching versions.
    if (tags.has("changeVersionContent")) return;
    if (!canPersistEditorChanges()) return;

    const serializedEditorState = editorState.toJSON();
    setSerializedEditorState(serializedEditorState);
  }

  /**
   * @param {import("lexical").SerializedEditorState<import("lexical").SerializedLexicalNode>} serializedEditorState
   */
  function saveEditorState(serializedEditorState) {
    // Commented because we need to allow counterparty to accept or reject redlines even when in read mode.
    // if (state.drawerVersions.active.editMode === "read") return;

    // For whatever reason, sometimes this value does not exist which means when we try to access it, it throws
    // an error.
    if (!state?.drawerVersions?.active?._id) return;

    const saveRoute =
      state.settings.api +
      (isTemplating ? "template" : "agrv") +
      "/newcontent/" +
      av._id;

    if (process.env.NODE_ENV === "development") {
      console.info("Saving editor content...");
    }

    axios
      .put(saveRoute, {
        newContent: serializedEditorState,
        contentMetadata: av.contentMetadata ?? {},
      })
      .then((res) => {
        if (!res.data.success) {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "An error occurred while saving your changes.",
              severity: "error",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while saving your changes.",
            severity: "error",
          },
        });
      });
  }

  const closeExportDocumentDialog = () => {
    setExportDocumentDialogIsLoading(true);
    setOpenExportDocumentDialog(false);
    window.setTimeout(() => {
      setExportDocumentDialogIsLoading(false);
    }, 1000);
  };

  /**
   * @param {"Export" | "Approvals" | string & {}} event
   */
  const handleDrawerEvents = (event) => {
    switch (event) {
      case "Export": {
        exportAgreement(true);
        break;
      }

      case "Approvals": {
        if (isTemplating) {
          setOpenDialogConfigureTemplate(true);
        } else {
          setOpenApprovalsAndReviewsDialog(true);
        }
        break;
      }

      default: {
        throw new Error(`Invalid event (${event}).`);
      }
    }
  };

  /**
   * @param {*} file
   * @param {*} fileName
   * @param {*} convert
   */
  const downloadPdfFile = async (file, fileName, convert) => {
    try {
      const result = await axios.get(
        // For PDF: Download to arraybuffer, for others - get a "viewable" temporary URL.
        `${state.settings.api}download/${convert ? "convertPdf/" : ""}${file}`,
        { responseType: "arraybuffer" }
      );

      download(result.data, fileName);
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to download origin file - contact Canveo Support",
          severity: "error",
        },
      });
    }
  };

  const onExportFinish = () => {
    if (!devMode) {
      setOpenExportDocumentDialog(false);
    }

    window.setTimeout(() => {
      setExportDocumentDialogIsLoading(false);
    }, 1000);
  };

  /**
   * @param {*} event
   * @param {*} payload
   */
  const handleExportDocumentDialogEvents = async (event, payload) => {
    switch (event) {
      case "Export": {
        setExportDocumentDialogIsLoading(true);

        if (exportFullAgreement && payload.type !== "docx") {
          const isInEffect = mainAg?.agrStatus === "InEffect";
          exportEditorToDocumentPluginRef.current
            .fullExport(payload, isTemplating, isInEffect)
            .then(onExportFinish);
          return;
        }

        if (["canveo", "docx"].includes(av.versionType)) {
          exportEditorToDocumentPluginRef.current
            .export(payload, isAgreementOwner, editMode)
            .then(onExportFinish);
          return;
        }

        if (payload.type === "xlsx") {
          await downloadFile(av.embeddedFileId, `${payload.filename}.xlsx`);
          closeExportDocumentDialog();
          return;
        }

        await downloadPdfFile(
          av.embeddedFileId,
          `${payload.filename}.pdf`,
          av.versionType === "xlsx"
        );
        closeExportDocumentDialog();

        break;
      }

      default: {
        throw new Error(`${event} is not a valid event.`);
      }
    }
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(
    /** @type {* | null} */ (null)
  );

  /**
   * @param {*} _floatingAnchorElem
   */
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  /**
   * @param {import("./editor/types/sfdt").Sfdt} sfdt
   * @returns {string}
   */
  function getSfdtDefaultFont(sfdt) {
    const normalStyle = sfdt.styles.find(
      (style, index) => index === 0 && style.name === "Normal"
    );

    let font = "";

    // First we try to get the font size from the normal style.
    if (normalStyle?.characterFormat?.fontFamily) {
      font = normalStyle.characterFormat?.fontFamily;
    }
    // If the normal style does not have any font size we fallback to to the font size from the root
    // `characterFormat`.
    else if (sfdt?.characterFormat?.fontFamily) {
      font = sfdt.characterFormat.fontFamily;
    }

    return font;
  }

  /**
   * @param {import("./editor/types/sfdt").Sfdt} sfdt
   * @returns {number}
   */
  function getSfdtDefaultFontSize(sfdt) {
    const normalStyle = sfdt.styles.find(
      (style, index) => index === 0 && style.name === "Normal"
    );

    let fontSize = 12;

    // First we try to get the font size from the normal style.
    if (normalStyle?.characterFormat?.fontSize) {
      fontSize = normalStyle.characterFormat?.fontSize;
    }
    // If the normal style does not have any font size we fallback to to the font size from the root
    // `characterFormat`.
    else if (sfdt?.characterFormat?.fontSize) {
      fontSize = sfdt.characterFormat.fontSize;
    }

    return fontSize;
  }

  useEffect(
    () => {
      // Set indentation base value for editor.
      if (av && containerWithScrollRef.current) {
        let tabWidth = 36,
          docWidth = EDITOR_WIDTH,
          docHeight;
        if (isTemplating) {
          tabWidth = props.template.sfdt.defaultTabWidth;
          const { sections } = props.template.sfdt;
          /** @type {import("./editor/types/sfdt").Section} */
          const firstSection = sections[0];
          if (firstSection) {
            const { sectionFormat } = firstSection;
            docWidth =
              sectionFormat.pageWidth -
              (sectionFormat.leftMargin + sectionFormat.rightMargin);
            docHeight =
              sectionFormat.pageHeight -
              (sectionFormat.bottomMargin + sectionFormat.topMargin);
          }
        } else {
          tabWidth = av.sfdt?.defaultTabWidth || defaultSfdt.defaultTabWidth;
          const { sections } = av.sfdt || defaultSfdt;
          /** @type {import("./editor/types/sfdt").Section} */
          const firstSection = sections[0];
          if (firstSection) {
            const { sectionFormat } = firstSection;
            docWidth =
              sectionFormat.pageWidth -
              (sectionFormat.leftMargin + sectionFormat.rightMargin);
            docHeight =
              sectionFormat.pageHeight -
              (sectionFormat.bottomMargin + sectionFormat.topMargin);
          }
        }
        const editorInput = containerWithScrollRef.current,
          editorHeader = document.getElementsByClassName("editor-header")[0],
          editorFooter = document.getElementsByClassName("editor-footer")[0];
        if (editorInput) {
          editorInput.style.setProperty(
            "--lexical-indent-base-value",
            tabWidth + "px"
          );
          editorInput.style.setProperty(
            "--lexical-doc-width",
            // Adding 55 because it makes it look more like the Word document.
            docWidth + 55 + "px"
          );
          editorInput.style.setProperty(
            "--lexical-doc-height",
            docHeight + "px"
          );
          if (docHeight) {
            editorInput.style.setProperty(
              "--lexical-doc-height",
              docHeight + "px"
            );
          }

          const sfdt = isTemplating ? props.template.sfdt : av.sfdt;

          const mainFontSize = getSfdtDefaultFontSize(sfdt);
          if (mainFontSize) {
            editorInput.style.setProperty(
              "--lexical-doc-fontSize",
              mainFontSize + "px"
            );
          }

          const headerFontSize = firstPageHeader?.characterFormat?.fontSize;
          if (headerFontSize) {
            // @ts-ignore
            editorHeader.style.setProperty(
              "--lexical-doc-fontSize",
              headerFontSize + "px"
            );
          }

          const footerFontSize = firstPageFooter?.characterFormat?.fontSize;
          if (footerFontSize) {
            // @ts-ignore
            editorFooter.style.setProperty(
              "--lexical-doc-fontSize",
              headerFontSize + "px"
            );
          }

          const mainFont = getSfdtDefaultFont(sfdt);
          if (mainFont) {
            editorInput.style.setProperty("--lexical-doc-font", mainFont);
          }
        }
      }
    },
    // Runs only when `av` and `containerWithScrollRef.current` change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [av, containerWithScrollRef.current]
  );

  /**
   * Whether or not the editor text can be edited.
   *
   * @returns {boolean}
   */
  function editorTextIsEditable() {
    // Do not allow editing the editor text if Canveo is in mobile/responsive mode.
    if (!isMdUp) return false;

    // Do not allow editing the editor text if we are in a template and the template is active.
    if (templateIsActive) return false;

    // Do not allow editing the editor text if we are in an agreement with "InEffect" or "Execution" status.
    if (["InEffect", "Execution"].includes(mainAg.agrStatus)) return false;

    // Do not allow editing the editor text if there is no current version or if the version is a "basedOn"
    // version.
    const [latestVersion] = state.drawerVersions.versions;
    if (
      !latestVersion ||
      latestVersion.basedOnApproved ||
      latestVersion.basedOnReviewed
    ) {
      return false;
    }

    // Do not allow editing if the active version is different from the latest version.
    if (latestVersion?._id !== state?.drawerVersions?.active?._id) {
      return false;
    }

    // Do not allow editing the editor text if the current user's role has restricted editing or sending.
    /** @type {{ restrictEditing: boolean, restrictSending: boolean }} */
    const userRole = state.user.role;
    if (userRole.restrictEditing || userRole.restrictSending) return false;

    // Do not allow editing the editor text if the current party has readonly role.
    if (partyRoleIsReadonly) return false;

    // If we have reached this far then the editor text can be edited!
    return true;
  }

  const exportAgreement = (isFull = false) => {
    setOpenExportDocumentDialog(true);
    setExportFullAgreement(isFull);
  };

  const showLockBox =
    // isAgreementOwner &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "calc(100%-105px)",
          margin: "0px",
          marginLeft: "105px",
          padding: "0px" /*, backgroundColor: '#ffeeff'*/,
        }}
      >
        {
          // TODO: Check if this code should happen at any moment
          // // Show the Header / Drawer for any non-Canveo Doc - for Canveo docs, the header will be part of the editor context
          // !["canveo"].includes(av.versionType) ? (
          //   <>
          //     <Header page={props.page} mainAg={mainAg} isOwner={isOwner} />
          //     <CoreDrawer page={props.page} template={props.template} />
          //   </>
          // ) : (
          //   ""
          // )
        }

        <Grid container direction="column" alignItems="center">
          {devMode && (
            <>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={4} />
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      copyLexicalJsonToClipboardPluginRef.current.copyJson();
                    }}
                  >
                    Copy Lexical JSON
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Button
                    onClick={() => {
                      copyLexicalJsonToClipboardPluginRef.current.copyHtml();
                    }}
                  >
                    Copy Lexical HTML
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            item
            container
            justifyContent="center"
            sx={{
              mt: state.appBar?.toolbarOpen ? "30px" : "0px",
              width: "100%",
            }}
          >
            {
              // FILE ATTACHMENT DOWN- AND UPLOAD
              Boolean(av) &&
              Boolean(av.content) &&
              ["docx", "pptx"].includes(av.versionType) &&
              file !== null ? (
                <div>
                  <Grid container direction="column" alignItems="center">
                    <Grid item sx={{ my: 2 }}>
                      <img
                        alt={av.versionType}
                        width={70}
                        style={{ cursor: "pointer" }}
                        onClick={handleDownloadFile}
                        src={
                          ["pptx"].includes(av.versionType)
                            ? pptx
                            : ["xlsx"].includes(av.versionType)
                            ? xlsx
                            : docx
                        }
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                      <Typography>
                        <b>Reference:</b> {av.content.file}
                      </Typography>
                      <Typography>
                        <b>Upload on:</b> {av.content.creationDate}
                      </Typography>
                      <Typography>
                        <b>Upload by:</b> {av.content.creationBy}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ my: 4 }}>
                      <RenderUpDownButtons size="large" />
                    </Grid>
                  </Grid>
                </div>
              ) : // CANVEO DOC (WITH EDITOR)
              partyID !== undefined &&
                partyID !== null &&
                defaultLexicalValue ? ( // We know the party ref of the current users' entity
                <Grid
                  item
                  xs={8}
                  justifyContent="center"
                  display="flex"
                  ref={containerWithScrollRef}
                >
                  <LexicalComposer
                    // @ts-ignore
                    initialConfig={{
                      ...editorConfig,
                      // We need to specify the namespace as "mainBody" here in order to be able to distinguish
                      // the main editor (this one) from the editors used below for the header and footer.
                      //
                      // This is needed so that we can prevent the footer and header editor from populating the
                      // global list handler. This logic is implemented in the first useEffect of the
                      // `ListExtendedPlugin`.
                      //
                      // At the moment, the global list handler is a singleton and only supports one instance
                      // across the application hence why we give it priority to the main body instead of the
                      // footers and headers.
                      //
                      // This means that at the moment it is not possible to manually create lists in the editor
                      // on footers and headers. To allow this we need to find a way of having a list handler per
                      // editor.
                      namespace: "mainBody",
                      editorState: JSON.stringify(
                        av.content || defaultLexicalValue
                      ),
                      // The real editable config is done in the isEditable={editorTextIsEditable()} in the
                      // tracked changes plugin below.
                      editable: true,
                    }}
                  >
                    <Header
                      page={props.page}
                      mainAg={mainAg}
                      av={av}
                      isOwner={isCurrentVersionOwner}
                      toolbar={<ToolbarPlugin sfdt={av.sfdt} />}
                      showToolbarBtn={true}
                      templateVersion={
                        isTemplating ? (
                          <TemplateVersionPlugin template={props.template} />
                        ) : null
                      }
                      isInEffect={
                        isTemplating
                          ? props.template.active
                          : ["InEffect", "Execution"].includes(mainAg.agrStatus)
                      }
                      showEditorToolbarByDefault={showEditorToolbarByDefault()}
                    />

                    {openDialogConfigureTemplate ? (
                      <DialogConfigureTemplate
                        open={openDialogConfigureTemplate}
                        close={() => {
                          setOpenDialogConfigureTemplate(false);
                          setSearchParams({});
                        }}
                        template={props.template}
                        taskType={null}
                        taskId={null}
                      />
                    ) : (
                      <></>
                    )}

                    {openApprovalsAndReviewsDialog ? (
                      <DialogOwnerSendOptions
                        open={openApprovalsAndReviewsDialog}
                        close={() => setOpenApprovalsAndReviewsDialog(false)}
                        agreement={mainAg}
                        taskType={null}
                        taskId={null}
                        dialogTitle="Approvals and Reviews"
                        approvalsTabTitle="Approvals"
                        reviewsTabTitle="Reviews"
                        hideSendAndSigningTabs
                        defaultSelectedTab={0}
                      />
                    ) : (
                      <></>
                    )}

                    <CoreDrawer
                      isTemplate={isTemplating}
                      hasLargeDrawer
                      drawerItems={drawerItems}
                      onEvent={handleDrawerEvents}
                      contents={<TocPlugin />}
                      definitions={<DefinitionsPlugin />}
                      exhibits={
                        <ExhibitsDrawer
                          isTemplate={isTemplating}
                          docID={docID}
                          hasPen={isCurrentVersionOwner}
                          handleExport={exportAgreement}
                          template={props.template}
                          isInEffect={
                            isTemplating
                              ? props.template.active
                              : ["InEffect", "Execution"].includes(
                                  mainAg.agrStatus
                                )
                          }
                        />
                      }
                      versions={
                        <VersionsPlugin
                          isTemplate={isTemplating}
                          docID={docID}
                          handleExport={exportAgreement}
                          isActive={props.template?.active}
                          isInEffect={
                            isTemplating
                              ? props.template.active
                              : ["InEffect", "Execution"].includes(
                                  mainAg.agrStatus
                                )
                          }
                        />
                      }
                      questionnaire={
                        <QuestionnaireDrawer
                          partyId={partyID}
                          documentIsTemplate={isTemplating}
                          docId={docID}
                          // @ts-ignore
                          user={state.user}
                        />
                      }
                      summary={
                        <SummaryDrawer
                          // @ts-ignore
                          docID={docID}
                          isTemplate={isTemplating}
                          partyId={partyID}
                        />
                      }
                      playbook={<PlaybookDrawer />}
                    />

                    {openExportDocumentDialog && isAgreementOwner ? (
                      <ExportDocumentDialog
                        openDialog={openExportDocumentDialog}
                        isLoading={exportDocumentDialogIsLoading}
                        closeDialog={closeExportDocumentDialog}
                        onEvent={handleExportDocumentDialogEvents}
                        agr={mainAg}
                        versionType={av.versionType}
                        isTemplate={isTemplating}
                        isAgreementOwner={isAgreementOwner}
                        editMode={editMode}
                      />
                    ) : (
                      <></>
                    )}

                    {openExportDocumentDialog && !isAgreementOwner ? (
                      <ExportDocumentDialogForCounterparties
                        openDialog={openExportDocumentDialog}
                        isLoading={exportDocumentDialogIsLoading}
                        closeDialog={closeExportDocumentDialog}
                        onEvent={handleExportDocumentDialogEvents}
                        agr={mainAg}
                        versionType={av.versionType}
                        isTemplate={isTemplating}
                        isAgreementOwner={isAgreementOwner}
                        editMode={editMode}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="editor-container" ref={onRef}>
                      {isAgrExec &&
                        ["Execution"].includes(mainAg.agrStatus) && (
                          <Grid container justifyContent="center">
                            <Grid item my={2}>
                              <AgrExecBox doc={mainAg} />
                            </Grid>
                          </Grid>
                        )}

                      {/* If a later version is owned by a counterparty, show a lock box to the current user. */}
                      {showLockBox && (
                        <LockBox agreement={mainAg} parties={state.parties} />
                      )}

                      {/* EDITOR START */}
                      <CanveoPlugin partyId={partyID} user={state.user} />
                      <ExportEditorToDocumentPlugin
                        ref={exportEditorToDocumentPluginRef}
                        // @ts-ignore
                        agrVersion={av}
                        partyId={partyID}
                        agreement={mainAg}
                        isTemplate={isTemplating}
                      />
                      <CopyLexicalContentsToClipboardPlugin
                        ref={copyLexicalJsonToClipboardPluginRef}
                      />
                      {/* @ts-ignore */}
                      <RichTextPlugin
                        ErrorBoundary={LexicalErrorBoundary}
                        contentEditable={
                          <div
                            className="editor"
                            style={{
                              opacity: readOnly ? 0.5 : 1,
                              transform: "scale(1.5)",
                              transformOrigin: "top",
                            }}
                          >
                            {firstPageHeader &&
                              // @ts-ignore
                              firstPageHeader?.root?.children?.length > 0 && (
                                <>
                                  <div style={{ visibility: "hidden" }}>
                                    header margin top hidden element
                                  </div>

                                  <LexicalComposer
                                    // @ts-ignore
                                    initialConfig={{
                                      ...editorConfig,
                                      namespace: "firstPageHeader",
                                      editorState:
                                        JSON.stringify(firstPageHeader),
                                      editable: false,
                                    }}
                                  >
                                    <RichTextPlugin
                                      placeholder={null}
                                      ErrorBoundary={LexicalErrorBoundary}
                                      contentEditable={
                                        <div className="editor-header">
                                          <ContentEditable />
                                        </div>
                                      }
                                    />
                                    <ListExtendedPlugin
                                      partyId={partyID}
                                      // @ts-ignore
                                      userDisplayName={state.user.displayName}
                                      user={state.user}
                                      // @ts-ignore
                                      sfdt={firstPageFooter.sfdt}
                                      listsStructure={
                                        av.contentMetadata?.listsStructure
                                      }
                                    />
                                    <ImagesPlugin />
                                    <ListPlugin />
                                    <LinkPlugin />
                                    <TablePlugin />
                                    <ListMaxIndentLevelPlugin maxDepth={8} />
                                    <TabIndentationPlugin />
                                    <CrossReferenceEventsPlugin state={state} />
                                    <TabIndentationPlugin />
                                  </LexicalComposer>
                                </>
                              )}

                            <ContentEditable
                              className="editor-input"
                              spellCheck={false}
                            />

                            {/* We hide the footer for now because of page numbering. */}
                            {/* {firstPageFooter &&
                              // @ts-ignore
                              firstPageFooter?.root?.children?.length > 0 && (
                                <>
                                  <LexicalComposer
                                    // @ts-ignore
                                    initialConfig={{
                                      ...editorConfig,
                                      namespace: "firstPageFooter",
                                      editorState:
                                        JSON.stringify(firstPageFooter),
                                      editable: false,
                                    }}
                                  >
                                    <RichTextPlugin
                                      placeholder={null}
                                      ErrorBoundary={LexicalErrorBoundary}
                                      contentEditable={
                                        <div className="editor-footer">
                                          <ContentEditable />
                                        </div>
                                      }
                                    />
                                    <ImagesPlugin />
                                    <ListPlugin />
                                    <ListExtendedPlugin
                                      partyId={partyID}
                                      // @ts-ignore
                                      userDisplayName={state.user.displayName}
                                      user={state.user}
                                      // @ts-ignore
                                      sfdt={firstPageFooter.sfdt}
                                      listsStructure={
                                        av.contentMetadata?.listsStructure
                                      }
                                    />
                                    <LinkPlugin />

                                    <TablePlugin />
                                    <ListMaxIndentLevelPlugin maxDepth={8} />
                                    <TabIndentationPlugin />
                                    <CrossReferenceEventsPlugin state={state} />
                                  </LexicalComposer>

                                  <div style={{ visibility: "hidden" }}>
                                    footer margin bottom hidden element
                                  </div>
                                </>
                              )} */}
                          </div>
                        }
                      />
                      <ListExtendedPlugin
                        partyId={partyID}
                        // @ts-ignore
                        userDisplayName={state.user.displayName}
                        user={state.user}
                        sfdt={av.sfdt}
                        listsStructure={av.contentMetadata?.listsStructure}
                      />
                      <HistoryPlugin />
                      <TabIndentationPlugin />
                      {/* @ts-ignore */}
                      <ImagesPlugin />
                      <ListPlugin />
                      <LinkPlugin />

                      <TablePlugin />
                      {floatingAnchorElem && (
                        <>
                          {/* Only display table cell dropdown menu for agreement owners on experimental tier. */}
                          {isAgreementOwner && (
                            <TableMenuPlugin anchorElem={floatingAnchorElem} />
                          )}
                          <FloatingContextMenuPlugin
                            anchorElem={floatingAnchorElem}
                            isInEffect={
                              isTemplating
                                ? props.template.active
                                : ["InEffect", "Execution"].includes(
                                    mainAg.agrStatus
                                  )
                            }
                            isTemplate={isTemplating}
                          />
                        </>
                      )}
                      <ListMaxIndentLevelPlugin maxDepth={8} />
                      <TabIndentationPlugin />
                      <TrackChangesPlugin
                        partyId={partyID}
                        // @ts-ignore
                        userDisplayName={state.user.displayName}
                        user={state.user}
                        isTemplate={isTemplating}
                        templateIsActive={templateIsActive}
                        docID={docID}
                        isEditable={editorTextIsEditable()}
                        agreementVersion={av}
                        isAgreementOwner={isAgreementOwner}
                      />
                      <WorkflowPlugin
                        partyID={partyID}
                        isTemplating={isTemplating}
                        docID={docID}
                        agrvID={state.drawerVersions.active?._id}
                        user={state.user}
                      />
                      <ClausePlugin partyID={partyID} />
                      <ClauseOptionsPlugin
                        partyID={partyID}
                        docID={docID}
                        isTemplating={isTemplating}
                        isInEffect={
                          isTemplating
                            ? props.template.active
                            : ["InEffect", "Execution"].includes(
                                mainAg.agrStatus
                              )
                        }
                      />
                      <OpenIssuesPlugin
                        anchorElem={floatingAnchorElem}
                        partyId={partyID}
                        isTemplating={isTemplating}
                        docID={docID}
                      />
                      <OnChangePlugin
                        onChange={onChange}
                        ignoreSelectionChange
                      />
                      <CrossReferenceEventsPlugin state={state} />
                      <PlaybookPlugin type="publisher" />

                      {/* Useful for debugging; Turn on devMode for additional debugging information from the editor */}
                      {devMode && <TreeViewPlugin />}
                      {/* EDITOR END */}
                    </div>

                    {!isTemplating && canveoTier === "experimental" ? (
                      <RightCoreDrawer
                        hasLargeDrawer
                        drawerItems={[
                          {
                            id: "editor-fast-track",
                            name: "Analysis",
                            icon: faBowArrow,
                          },
                        ]}
                        fasttrack={
                          <FastTrackDrawer agreementVersionId={av._id} />
                        }
                        onEvent={handleDrawerEvents}
                      />
                    ) : (
                      <></>
                    )}
                  </LexicalComposer>
                </Grid>
              ) : (
                <CanveoCircularProgress />
              )
            }
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
