/** @import { Sfdt, Style, ParagraphFormat } from "../../types/sfdt" */

import { getAbstractListByListId } from "./getAbstractListFromListId";

/**
 * Recursively compiles the paragraph format of the style based on the `basedOn` property.
 *
 * @param {Sfdt} sfdt
 * @param {string} styleName
 * @returns {ParagraphFormat | null}
 */
export function compileStyleParagraphFormat(sfdt, styleName = "") {
  // Find the style object by name.
  const style = sfdt.styles.find((s) => s.name === styleName);
  // Return null if the style is not found.
  if (!style) return null;

  // Base paragraph format of the current style.
  let paragraphFormat = { ...style.paragraphFormat };

  // If the style has a `basedOn` attribute, inherit properties recursively.
  if (style.basedOn) {
    const parentFormat = compileStyleParagraphFormat(sfdt, style.basedOn);
    // Merge parent paragraphFormat with the current one (parent properties take precedence).
    paragraphFormat = { ...parentFormat, ...paragraphFormat };
  }

  let styleListFormat = style.paragraphFormat?.listFormat;
  if (styleListFormat) {
    const abstractList = getAbstractListByListId(
      sfdt.lists,
      sfdt.abstractLists,
      styleListFormat.listId
    );
    if (abstractList) {
      const level = abstractList.levels[styleListFormat.listLevelNumber];
      if (level?.paragraphFormat) {
        return {
          ...paragraphFormat,
          ...level.paragraphFormat,
        };
      }
    }
  }

  return paragraphFormat;
}
