import { TableNode } from "@lexical/table";
import { $applyNodeReplacement } from "lexical";
import { TABLE_NODE_TYPE } from "../utils/constants";

/**
 * This is to ease imports and legibility across the file
 *
 * @typedef {import("../types/sfdt").Left} Left
 * @typedef {import("../types/sfdt").Right} Right
 * @typedef {import("../types/sfdt").Top} Top
 * @typedef {import("../types/sfdt").Bottom} Bottom
 * @typedef {import("../types/sfdt").Horizontal} Horizontal
 * @typedef {import("../types/sfdt").Vertical} Vertical
 * @typedef {import("../types/sfdt").DiagonalUp} DiagonalUp
 * @typedef {import("../types/sfdt").DiagonalDown} DiagonalDown
 */
export class CustomTableNode extends TableNode {
  /** @internal @type {Left} */
  __borderLeft;
  /** @internal @type {Right} */
  __borderRight;
  /** @internal @type {Top} */
  __borderTop;
  /** @internal @type {Bottom} */
  __borderBottom;
  /** @internal @type {Horizontal} */
  __borderHorizontal;
  /** @internal @type {Vertical} */
  __borderVertical;
  /** @internal @type {DiagonalUp} */
  __borderDiagonalUp;
  /** @internal @type {DiagonalDown} */
  __borderDiagonalDown;
  /** @internal @type {string?} */
  __title;
  /** @internal @type {string?} */
  __description;
  /** @internal @type {import("../types/sfdt").TableFormat?} */
  /** @type {import("../types/lexical").LineSpacing | undefined}*/
  __lineSpacing;
  /** @type {import("../types/lexical").ParagraphSpacing | undefined}*/
  __paragraphSpacing;
  /** @type {import("../types/lexical").Indentation | undefined}*/
  __indentation;

  /**
   * @param {import("../types/lexical").LineSpacing} [lineSpacing]
   * @param {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
   * @param {import("../types/lexical").Indentation} [indentation]
   * @param {{
   *    borderLeft: Left,
   *    borderRight: Right,
   *    borderTop: Top,
   *    borderBottom: Bottom,
   *    borderHorizontal: Horizontal,
   *    borderVertical: Vertical,
   *    borderDiagonalUp: DiagonalUp,
   *    borderDiagonalDown: DiagonalDown }} [borders]
   * @param {string} [key]
   */
  constructor(lineSpacing, paragraphSpacing, indentation, borders, key) {
    super(key);
    this.__borderLeft = borders?.borderLeft;
    this.__borderRight = borders?.borderRight;
    this.__borderTop = borders?.borderTop;
    this.__borderBottom = borders?.borderBottom;
    this.__borderHorizontal = borders?.borderHorizontal;
    this.__borderVertical = borders?.borderVertical;
    this.__borderDiagonalUp = borders?.borderDiagonalUp;
    this.__borderDiagonalDown = borders?.borderDiagonalDown;
    this.__tableFormat = null;
    this.__lineSpacing = lineSpacing;
    this.__paragraphSpacing = paragraphSpacing;
    this.__indentation = indentation;
  }

  static getType() {
    return TABLE_NODE_TYPE;
  }

  getGrid() {
    const grid = {
      cells: [],
      rows: 0,
      columns: 0,
    };
    /** @type {TableRowNode[]} */
    const children = this.getChildren();
    children.forEach((/** @type {TableRowNode} */ row) => {
      let cols = 0;
      grid.rows++;
      row.getChildren().forEach((col) => {
        cols++;
        grid.cells.push(col);
      });
      if (grid.columns <= cols) {
        grid.columns = cols;
      }
    });
    return grid;
  }

  /** @param {"Left"| "Right"| "Top" | "Bottom" | "Horizontal" | "Vertical" | "DiagonalDown" | "DiagonalUp"} border*/
  getBorder(border) {
    if (
      ![
        "Left",
        "Right",
        "Top",
        "Bottom",
        "Horizontal",
        "Vertical",
        "DiagonalDown",
        "DiagonalUp",
      ].includes(border)
    ) {
      throw new Error(
        "Invalid argument exception. Border parameter must have one of the following values:" +
          ' "Left", "Right", "Top", "Bottom", "Horizontal", "Vertical", "DiagonalDown", "DiagonalUp"'
      );
    }
    const self = this.getLatest();
    return self[`__border${border}`];
  }

  getTitle() {
    const self = this.getLatest();
    return self.__title;
  }

  getDescription() {
    const self = this.getLatest();
    return self.__description;
  }
  getTableFormat() {
    const self = this.getLatest();
    return self.__tableFormat;
  }

  /** @returns {LineSpacing | undefined} */
  get lineSpacing() {
    const self = this.getLatest();
    return self.__lineSpacing;
  }

  /** @returns {ParagraphSpacing | undefined} */
  get paragraphSpacing() {
    const self = this.getLatest();
    return self.__paragraphSpacing;
  }

  /** @returns {Indentation | undefined} */
  get indentation() {
    const self = this.getLatest();
    return self.__indentation;
  }

  /** @param {LineSpacing} value */
  set lineSpacing(value) {
    const self = this.getWritable();
    self.__lineSpacing = value;
  }

  /** @param {ParagraphSpacing} value */
  set paragraphSpacing(value) {
    const self = this.getWritable();
    self.__paragraphSpacing = value;
  }

  /** @param {Indentation} value */
  set indentation(value) {
    const self = this.getWritable();
    self.__indentation = value;
  }

  setTableFormat(tableFormat) {
    const self = this.getWritable();
    self.__tableFormat = tableFormat;
    return this;
  }

  setTitle(title) {
    const self = this.getWritable();
    self.__title = title;
    return this;
  }

  setDescription(desc) {
    const self = this.getWritable();
    self.__description = desc;
    return this;
  }

  /**
   * @param {"Left"| "Right"| "Top" | "Bottom" | "Horizontal" | "Vertical" | "DiagonalDown" | "DiagonalUp"} border
   * @param { Left | Right | Top | Bottom | Horizontal | Vertical | DiagonalUp | DiagonalDown} value
   */
  setBorder(border, value) {
    if (
      ![
        "Left",
        "Right",
        "Top",
        "Bottom",
        "Horizontal",
        "Vertical",
        "DiagonalDown",
        "DiagonalUp",
      ].includes(border)
    ) {
      throw new Error(
        "Invalid argument exception. Border parameter must have one of the following values:" +
          ' "Left", "Right", "Top", "Bottom", "Horizontal", "Vertical", "DiagonalDown", "DiagonalUp"'
      );
    }
    const self = this.getWritable();
    if (value === undefined) {
      self[`__border${border}`] = undefined;
    }
    self[`__border${border}`] = {
      ...self[`__border${border}`],
      ...value,
    };
  }

  /**
   *
   * @param {import("./CustomTableCellNode").CustomTableCellNode} tableCellNode
   * @returns {{x: number, y: number}}
   */
  findNodeCoords(tableCellNode) {
    const coords = { x: null, y: null };
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      /** @type {TableRowNode} */
      const row = children[i];
      coords.x = i;
      row.getChildren().forEach((col, idx2) => {
        if (col.getKey() === tableCellNode.getKey()) {
          coords.y = idx2;
        }
      });
      if (coords.y) {
        break;
      }
    }
    return coords;
  }

  /**
   *
   * @param {import("./CustomTableCellNode").CustomTableCellNode} tableCellNode
   * @returns {boolean}
   */
  isLastOfRow(tableCellNode) {
    let isLastElementOfRow = false;
    /** @type {TableRowNode[]} */
    const children = this.getChildren();
    const elRow = children.find((r) =>
      r.getChildren().find((c) => c.getKey() === tableCellNode.getKey())
    );
    if (!elRow) return isLastElementOfRow;
    const cells = elRow.getChildren();
    const childrenSize = elRow.getChildrenSize();
    for (let i = 0; i < childrenSize; i++) {
      if (
        cells[i].getKey() === tableCellNode.getKey() &&
        i === childrenSize - 1
      ) {
        isLastElementOfRow = true;
      }
    }
    return isLastElementOfRow;
  }

  getNumberOfRowFromCell(tableCellNode) {
    const rows = this.getChildren();
    for (let i = 0; i < this.getChildrenSize(); i++) {
      const row = rows[i];
      if (
        row
          .getChildren()
          .find((cell) => cell.getKey() === tableCellNode.getKey())
      ) {
        return i;
      }
    }
  }

  /** @param {CustomTableNode} node */
  static clone(node) {
    const t = new CustomTableNode(
      node.__lineSpacing,
      node.__paragraphSpacing,
      node.__indentation,
      {
        borderLeft: node.__borderLeft,
        borderRight: node.__borderRight,
        borderTop: node.__borderTop,
        borderBottom: node.__borderBottom,
        borderHorizontal: node.__borderHorizontal,
        borderVertical: node.__borderVertical,
        borderDiagonalUp: node.__borderDiagonalUp,
        borderDiagonalDown: node.__borderDiagonalDown,
      },
      node.__key
    );
    t.__tableFormat = node.__tableFormat;
    return t;
  }

  /**
   * @typedef {Object} SerializedCustomTableNodeType
   * @property {string?} title
   * @property {string?} description
   *
   *
   * @typedef {import("@lexical/table/LexicalTableNode").SerializedTableNode & SerializedCustomTableNodeType} SerializedCustomTableNode
   */
  /** @param {SerializedCustomTableNode} serializedNode */
  static importJSON(serializedNode) {
    const t = $createCustomTableNode(
      serializedNode.__lineSpacing,
      serializedNode.__paragraphSpacing,
      serializedNode.__indentation,
      {
        borderLeft: serializedNode.__borderLeft,
        borderRight: serializedNode.__borderRight,
        borderTop: serializedNode.__borderTop,
        borderBottom: serializedNode.__borderBottom,
        borderHorizontal: serializedNode.__borderHorizontal,
        borderVertical: serializedNode.__borderVertical,
        borderDiagonalUp: serializedNode.__borderDiagonalUp,
        borderDiagonalDown: serializedNode.__borderDiagonalDown,
      }
    );
    t.setTableFormat(serializedNode.__tableFormat);
    t.setDirection(serializedNode.direction);
    t.setTitle(serializedNode.title);
    t.setDescription(serializedNode.description);
    return t;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      //TODO: this __[name] format might be wrong
      __borderLeft: this.getBorder("Left"),
      __borderRight: this.getBorder("Right"),
      __borderTop: this.getBorder("Top"),
      __borderBottom: this.getBorder("Bottom"),
      __borderHorizontal: this.getBorder("Horizontal"),
      __borderVertical: this.getBorder("Vertical"),
      __borderDiagonalUp: this.getBorder("DiagonalUp"),
      __borderDiagonalDown: this.getBorder("DiagonalDown"),
      __tableFormat: this.getTableFormat(),
      lineSpacing: this.__lineSpacing,
      paragraphSpacing: this.__paragraphSpacing,
      indentation: this.__indentation,
      type: TABLE_NODE_TYPE,
    };
  }

  /** @param {import("lexical").EditorConfig} config*/
  createDOM(config) {
    /** @type {HTMLTableElement} */
    const table = super.createDOM(config);
    const tableFormat = this.getTableFormat();
    table.className = config.theme.table;
    if (tableFormat?.shading?.backgroundColor) {
      table.style.setProperty(
        "--table-background-color",
        tableFormat?.shading?.backgroundColor
      );
    }

    // Commented out old logic.
    // table.classList.add(
    //   this.getDirection() ?? "ltr",
    //   tableFormat?.preferredWidthType !== "Auto"
    //     ? "table-width-min"
    //     : "table-width-max"
    // );

    const allBorders = this.getAllBorders();
    Object.keys(allBorders).forEach(
      /** @param {"borderLeft" | "borderRight" | "borderTop" | "borderBottom" | "borderVertical" | "borderHorizontal" | "borderDiagonalDown" | "borderDiagonalUp"} k */
      (k) => {
        if (allBorders[k]) {
          const secondPartOfKey = k.slice(6).toLowerCase();
          if (allBorders[k].hasNoneStyle) {
            if (
              [
                "borderTop",
                "borderBottom",
                "borderRight",
                "borderLeft",
              ].includes(k)
            ) {
              table.classList.add(`${secondPartOfKey}-clear`);
              table.style[`${k}`] = "none";
            } else if (["borderVertical", "borderHorizontal"].includes(k)) {
              table.classList.add(secondPartOfKey);
              table.style.setProperty(`--${secondPartOfKey}-lineWidth`, 0);
            }
          } else if (
            !allBorders[k].hasNoneStyle &&
            ["borderTop", "borderBottom", "borderRight", "borderLeft"].includes(
              k
            )
          ) {
            table.style[`${k}Color`] = allBorders[k].color;
            table.style[`${k}Width`] = allBorders[k].lineWidth + "px";
            table.style[`${k}Style`] = "solid";
          } else if (!allBorders[k].hasNoneStyle) {
            if (["borderVertical", "borderHorizontal"].includes(k)) {
              const secondPartOfKey = k.slice(6).toLowerCase();
              table.classList.add(secondPartOfKey);
              table.style.setProperty(
                `--${secondPartOfKey}-color`,
                allBorders[k].color
              );
              table.style.setProperty(
                `--${secondPartOfKey}-lineWidth`,
                allBorders[k].lineWidth + "px"
              );
              table.style.setProperty(
                `--${secondPartOfKey}-lineStyle`,
                "solid"
              );
            }
          }
          //should we preseve diagonal? might cause problems
        }
      }
    );
    table.style.direction = this.getDirection() ?? "ltr";
    const formatType = this.getFormatType();
    if (formatType) table.style.textAlign = formatType;
    if (this.__lineSpacing) {
      const { lineSpacing, lineSpacingType } = this.__lineSpacing;
      switch (lineSpacingType) {
        case "AtLeast": {
          // We do not care about this.
          break;
        }
        case "Exactly":
          table.style.lineHeight = `${lineSpacing.toPrecision(2)}px`;
          break;
        case "Multiple":
        default:
          table.style.lineHeight = lineSpacing.toPrecision(2);
          break;
      }
    }
    if (this.__indentation) {
      const { firstLineIndent, leftIndent, rightIndent } = this.__indentation;
      if (firstLineIndent) {
        table.style.textIndent = `${firstLineIndent}px`;
      }
      if (leftIndent) {
        table.style.marginLeft = `${leftIndent}px`;
      }
      if (rightIndent) {
        table.style.marginRight = `${rightIndent}px`;
      }
    }
    if (this.__paragraphSpacing) {
      const { afterSpacing, beforeSpacing } = this.__paragraphSpacing;
      if (beforeSpacing) table.style.marginTop = `${beforeSpacing}px`;
      if (afterSpacing) table.style.marginBottom = `${afterSpacing}px`;
    }

    // Table alignment.
    if (typeof tableFormat.tableAlignment === "string") {
      switch (tableFormat.tableAlignment) {
        case "Left": {
          table.style.marginLeft = "0";
          table.style.marginRight = "auto";
          break;
        }

        case "Center": {
          table.style.margin = "auto";
          break;
        }

        case "Right": {
          table.style.marginLeft = "auto";
          table.style.marginRight = "0";
          break;
        }

        default: {
          break;
        }
      }
    }

    if (tableFormat?.preferredWidthType === "Percent") {
      // We need this in order for cells with percent width to work.
      table.style.width = "100%";
    }

    if (tableFormat?.preferredWidthType === "Point") {
      // We need this in order for cells with percent width to work.
      table.style.width = tableFormat.tableWidth + "px";
    }

    return table;
  }

  /**
   *
   * @returns {{borderLeft: Left, borderTop: Top, borderBottom: Bottom, borderRight: Right, borderVertical: Vertical, borderHorizontal: Horizontal,borderDiagonalDown: DiagonalDown, borderDiagonalUp: DiagonalUp}}
   */
  getAllBorders() {
    const self = this.getLatest();
    const borders = {
      borderLeft: self.__borderLeft,
      borderRight: self.__borderRight,
      borderTop: self.__borderTop,
      borderBottom: self.__borderBottom,
      borderHorizontal: self.__borderHorizontal,
      borderVertical: self.__borderVertical,
      borderDiagonalUp: self.__borderDiagonalUp,
      borderDiagonalDown: self.__borderDiagonalDown,
    };
    return borders;
  }
}

/**
 *  @param {{
 *    borderLeft: Left,
 *    borderRight: Right,
 *    borderTop: Top,
 *    borderBottom: Bottom,
 *    borderHorizontal: Horizontal,
 *    borderVertical: Vertical,
 *    borderDiagonalUp: DiagonalUp,
 *    borderDiagonalDown: DiagonalDown }} [borders]
 * @returns {CustomTableNode}
 */
export function $createCustomTableNode(
  lineSpacing,
  paragraphSpacing,
  indentation,
  borders
) {
  return $applyNodeReplacement(
    new CustomTableNode(
      lineSpacing,
      paragraphSpacing,
      indentation,
      borders,
      undefined
    )
  );
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @returns {node is CustomTableNode}
 */
export function $isCustomTable(node) {
  return node instanceof CustomTableNode;
}
