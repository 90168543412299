import { normalizeValue } from "./normalizeValue";
import { romanize } from "./romanize";

/**
 * Returns number in decimal, roman or letter format depending on format given.
 *
 * @param {number} value
 * @param {MarkerPattern | undefined} pattern
 */
export function parseValueForHTML(value, pattern) {
  switch (pattern) {
    case "UpRoman": {
      return romanize(value);
    }
    case "LowRoman": {
      // @ts-ignore
      return romanize(value).toLowerCase();
    }
    case "UpLetter": {
      return String.fromCharCode(64 + normalizeValue(value)); //ASCII lower case
    }
    case "LowLetter": {
      return String.fromCharCode(96 + normalizeValue(value)); //ASCII upper case
    }
    default: {
      return value;
    }
  }
}
