/**
 * The `GlobalListHandler` is used by `ListExtendedPlugin` to import lists and on `Lexical` nodes of type
 * `CustomListNode` and `CustomListItemNode`.
 *
 * It is used to aid in the generation of values for list items and as  a side structure for synchronizing lists
 * inside the `Lexical` editor.
 *
 * There is only one instance of the `GlobalListHandler` which uses the singleton pattern.
 *
 * This structure is populated in a `useEffect` on the `ListExtendedPlugin` which then calls the
 * `populateGlobalListHandler` function.
 */
export const GlobalListHandler = (function () {
  /** @type {ListHandlerInstance | undefined} */
  let instance;

  /**
   * @returns {ListHandlerInstance}
   */
  function createInstance() {
    const object = {
      lists: {},
      root: [],
      currentSfdt: {},
    };

    // @ts-ignore
    return object;
  }

  return {
    reset: function () {
      instance = undefined;
    },
    /**
     * @returns {ListHandlerInstance}
     */
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    /**
     * @returns {PseudoListItem[]}
     */
    getRoot: function () {
      const instance = this.getInstance();
      return instance.root;
    },
    /**
     * Returns the root (list of list items) in reverse order. The array is copied before being reversed since the
     * reverse function mutates the original array.
     *
     * @returns {PseudoListItem[]}
     */
    getReversedRoot: function () {
      const instance = this.getInstance();
      return [...instance.root].reverse();
    },
    /**
     * @returns {import("../../types/sfdt").AbstractList[]}
     */
    getLists: function () {
      const instance = this.getInstance();
      return instance.lists;
    },
  };
})();
