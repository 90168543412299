import { TableCellNode } from "@lexical/table";
import { $applyNodeReplacement } from "lexical";
import { TABLE_CELL_NODE_TYPE, TABLE_NODE_TYPE } from "../utils/constants";
/**
 * This is to ease imports and legibility across the file
 *
 * @typedef {import("../types/sfdt").Left} Left
 * @typedef {import("../types/sfdt").Right} Right
 * @typedef {import("../types/sfdt").Top} Top
 * @typedef {import("../types/sfdt").Bottom} Bottom
 * @typedef {import("../types/sfdt").Horizontal} Horizontal
 * @typedef {import("../types/sfdt").Vertical} Vertical
 * @typedef {import("../types/sfdt").DiagonalUp} DiagonalUp
 * @typedef {import("../types/sfdt").DiagonalDown} DiagonalDown
 */
export class CustomTableCellNode extends TableCellNode {
  /**@type {number} */
  __rowSpan;
  /** @type {Left | undefined} */
  __borderLeft;
  /** @type {Right | undefined} */
  __borderRight;
  /** @type {Top | undefined} */
  __borderTop;
  /** @type {Bottom | undefined} */
  __borderBottom;
  /** @type {Horizontal | undefined} */
  __borderHorizontal;
  /** @type {Vertical | undefined} */
  __borderVertical;
  /** @type {DiagonalUp | undefined} */
  __borderDiagonalUp;
  /** @type {DiagonalDown | undefined} */
  __borderDiagonalDown;
  /** @type {import("../types/sfdt").CellFormat} */
  __cellFormat;

  static getType() {
    return TABLE_CELL_NODE_TYPE;
  }

  /**
   * @param {"Left"| "Right"| "Top" | "Bottom" | "Horizontal" | "Vertical" | "DiagonalDown" | "DiagonalUp"} border
   * @returns {Left | Right | Top | Bottom | Horizontal | Vertical | DiagonalUp | DiagonalDown | undefined}
   */
  getBorder(border) {
    if (
      ![
        "Left",
        "Right",
        "Top",
        "Bottom",
        "Horizontal",
        "Vertical",
        "DiagonalDown",
        "DiagonalUp",
      ].includes(border)
    ) {
      throw new Error(
        "Invalid argument exception. Border parameter must have one of the following values:" +
          ' "Left", "Right", "Top", "Bottom", "Horizontal", "Vertical", "DiagonalDown", "DiagonalUp"'
      );
    }
    /** @type {CustomTableCellNode} */
    const self = this.getLatest();
    /** @type {Left | Right | Top | Bottom | Horizontal | Vertical | DiagonalUp | DiagonalDown} */
    const res = self[`__border${border}`];
    return res;
  }

  /**
   * @param {"Left"| "Right"| "Top" | "Bottom" | "Horizontal" | "Vertical" | "DiagonalDown" | "DiagonalUp"} border
   * @param { Left | Right | Top | Bottom | Horizontal | Vertical | DiagonalUp | DiagonalDown} value
   */
  setBorder(border, value) {
    if (
      ![
        "Left",
        "Right",
        "Top",
        "Bottom",
        "Horizontal",
        "Vertical",
        "DiagonalDown",
        "DiagonalUp",
      ].includes(border)
    ) {
      throw new Error(
        "Invalid argument exception. Border parameter must have one of the following values:" +
          ' "Left", "Right", "Top", "Bottom", "Horizontal", "Vertical", "DiagonalDown", "DiagonalUp"'
      );
    }
    const self = this.getWritable();
    if (value === undefined) {
      self[`__border${border}`] = undefined;
    }
    self[`__border${border}`] = {
      ...self[`__border${border}`],
      ...value,
    };
  }

  getColSpan() {
    const self = this.getLatest();
    return self.__colSpan;
  }

  getRowSpan() {
    const self = this.getLatest();
    return self.__rowSpan;
  }

  getCellFormat() {
    const self = this.getLatest();
    return self.__cellFormat;
  }

  /** @param {number} rowSpan*/
  setRowSpan(rowSpan) {
    const self = this.getWritable();
    self.__rowSpan = rowSpan;
    return self;
  }

  /**
   *
   * @param {import("@lexical/table/LexicalTableCellNode").TableCellHeaderState} [headerState]
   * @param {number} [colSpan]
   * @param {number} [width]
   * @param {string} [key]
   * @param {number} [rowSpan]
   * @param {{
   *    borderLeft: Left,
   *    borderRight: Right,
   *    borderTop: Top,
   *    borderBottom: Bottom,
   *    borderHorizontal: Horizontal,
   *    borderVertical: Vertical,
   *    borderDiagonalUp: DiagonalUp,
   *    borderDiagonalDown: DiagonalDown }} [borders]
   */
  constructor(
    headerState,
    colSpan,
    width,
    key,
    rowSpan = 1,
    borders = undefined,
    cellFormat = undefined
  ) {
    super(headerState, colSpan, width, key);
    this.__rowSpan = rowSpan;
    this.__borderLeft = borders?.borderLeft;
    this.__borderRight = borders?.borderRight;
    this.__borderTop = borders?.borderTop;
    this.__borderBottom = borders?.borderBottom;
    this.__borderHorizontal = borders?.borderHorizontal;
    this.__borderVertical = borders?.borderVertical;
    this.__borderDiagonalUp = borders?.borderDiagonalUp;
    this.__borderDiagonalDown = borders?.borderDiagonalDown;
    this.__cellFormat = cellFormat;
  }

  /** @param {CustomTableCellNode} node */
  static clone(node) {
    return new CustomTableCellNode(
      node.__headerState,
      node.__colSpan,
      node.__width,
      node.__key,
      node.__rowSpan,
      {
        borderLeft: node.__borderLeft,
        borderRight: node.__borderRight,
        borderTop: node.__borderTop,
        borderBottom: node.__borderBottom,
        borderHorizontal: node.__borderHorizontal,
        borderVertical: node.__borderVertical,
        borderDiagonalUp: node.__borderDiagonalUp,
        borderDiagonalDow: node.__borderDiagonalDown,
      },
      node.__cellFormat
    );
  }

  /**
   * @typedef {Object} SerializedTableCellNodeType
   * @property {number} rowSpan
   * @property {Left} borderLeft
   * @property {Right} borderRight
   * @property {Top} borderTop
   * @property {Bottom} borderBottom
   * @property {Horizontal} borderHorizontal
   * @property {Vertical} borderVertical
   * @property {DiagonalUp} borderDiagonalUp
   * @property {DiagonalDown} borderDiagonalDown
   *
   * @param {import("@lexical/table/LexicalTableCellNode").SerializedTableCellNode & SerializedTableCellNodeType} serializedNode
   *
   * @returns {CustomTableCellNode}
   */
  static importJSON(serializedNode) {
    return $createCustomTableCellNode(
      serializedNode.headerState,
      serializedNode.colSpan,
      serializedNode.width,
      serializedNode.rowSpan,
      {
        borderLeft: serializedNode.__borderLeft,
        borderRight: serializedNode.__borderRight,
        borderTop: serializedNode.__borderTop,
        borderBottom: serializedNode.__borderBottom,
        borderHorizontal: serializedNode.__borderHorizontal,
        borderVertical: serializedNode.__borderVertical,
        borderDiagonalUp: serializedNode.__borderDiagonalUp,
        borderDiagonalDown: serializedNode.__borderDiagonalDown,
      },
      serializedNode.__cellFormat
    );
  }

  /**
   * @returns {import("@lexical/table/LexicalTableCellNode").SerializedTableCellNode & SerializedTableCellNodeType}
   */
  exportJSON() {
    return {
      ...super.exportJSON(),
      colSpan: this.getColSpan(),
      rowSpan: this.getRowSpan(),
      __borderLeft: this.getBorder("Left"),
      __borderRight: this.getBorder("Right"),
      __borderTop: this.getBorder("Top"),
      __borderBottom: this.getBorder("Bottom"),
      __borderHorizontal: this.getBorder("Horizontal"),
      __borderVertical: this.getBorder("Vertical"),
      __borderDiagonalUp: this.getBorder("DiagonalUp"),
      __borderDiagonalDown: this.getBorder("DiagonalDown"),
      __cellFormat: this.getCellFormat(),
      type: TABLE_CELL_NODE_TYPE,
    };
  }

  /**
   * @param {import("lexical").EditorConfig} config
   * @returns {HTMLTableCellElement}
   */
  createDOM(config) {
    /** @type {HTMLTableCellElement} */
    const td = super.createDOM(config);
    const colSpan = this.getColSpan();
    const rowSpan = this.getRowSpan();
    const cellFormat = this.getCellFormat();

    // Set text vertical alignment.
    if (["Top", "Bottom"].includes(cellFormat.verticalAlignment)) {
      td.style.setProperty("vertical-align", cellFormat.verticalAlignment);
    }

    // Set cell (column) width.
    if (cellFormat.preferredWidth) {
      if (cellFormat.preferredWidthType === "Point") {
        td.style.width = `${cellFormat.cellWidth}px`;
      }

      if (cellFormat.preferredWidthType === "Percent") {
        td.style.width = `${cellFormat.preferredWidth}%`;
      }
    }

    if (colSpan > 0) td.colSpan = colSpan;
    if (rowSpan > 0) td.rowSpan = rowSpan;
    if (cellFormat?.shading?.backgroundColor) {
      td.style.setProperty(
        "--cell-background-color",
        cellFormat?.shading?.backgroundColor
      );
    }
    ["Left", "Bottom", "Top", "Right"].forEach((k) => {
      if (this[`__border${k}`]) {
        if (!this[`__border${k}`].hasNoneStyle) {
          //it has some style
          if (
            !(
              this[`__border${k}`].lineStyle === "None" &&
              this[`__border${k}`].lineWidth === 0 &&
              this[`__border${k}`].shadow === false &&
              this[`__border${k}`].space === 0
            )
          ) {
            //if there's any style set
            td.style[`border${k}Color`] = this[`__border${k}`].color;
            td.style[`border${k}Width`] = this[`__border${k}`].lineWidth + "px";
            td.style[`border${k}Style`] = "solid";
          }
        } else {
          //it should have none style
          if (
            ["borderTop", "borderBottom", "borderRight", "borderLeft"].includes(
              k
            )
          ) {
            td.style[`${k}`] = "none";
          } else if (["borderVertical", "borderHorizontal"].includes(k)) {
            const secondPartOfKey = k.slice(6).toLowerCase();
            td.classList.add(secondPartOfKey);
            td.style.setProperty(`--${secondPartOfKey}-lineWidth`, 0);
          }
        }
      }
    });
    return td;
  }

  /**
   *
   * @returns {Collisions | undefined}
   */
  getCollisions() {
    const table = this.getParentTable();
    if (!table) return;
    const collisions = {
      collidesLeft: false,
      collidesRight: false,
      collidesTop: false,
      collidesBottom: false,
    };
    const { x, y } = table.findNodeCoords(this);
    if (x === 0) {
      collisions.collidesLeft = true;
    }
    if (y === 0) {
      collisions.collidesTop = true;
    }
    const isLastOfRow = table.isLastOfRow(this);
    if (isLastOfRow) {
      //TODO: get coordinates in row
      collisions.collidesRight = true;
    }
    if (y === table.getNumberOfRowFromCell(this)) {
      //TODO: get row number (must be 0)
      collisions.collidesBottom = true;
    }
    return collisions;
  }

  /**
   * Find parent table.
   *
   * Returns null if it doesn't find a table parent.
   *
   * Returns a CustomTableNode if it finds a parent table.
   *
   * @returns {null | import("./CustomTableNode").CustomTableNode}
   */
  getParentTable() {
    let currentParent = this.getParent();
    while (currentParent?.getType() !== TABLE_NODE_TYPE) {
      currentParent = currentParent.getParent();
      if (!currentParent) {
        break;
      }
    }
    return currentParent;
  }
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is CustomTableCellNode}
 */
export function $isCustomTableCellNode(node) {
  return node instanceof CustomTableCellNode;
}

/**
 *
 * @param {number?} [headerState]
 * @param {number?} [colSpan]
 * @param {number?} [width]
 *  @param {{
 *    borderLeft: Left,
 *    borderRight: Right,
 *    borderTop: Top,
 *    borderBottom: Bottom,
 *    borderHorizontal: Horizontal,
 *    borderVertical: Vertical,
 *    borderDiagonalUp: DiagonalUp,
 *    borderDiagonalDown: DiagonalDown }} [borders]
 * @returns {CustomTableCellNode}
 */
export function $createCustomTableCellNode(
  headerState,
  colSpan,
  width,
  rowSpan,
  borders,
  cellFormat
) {
  return $applyNodeReplacement(
    new CustomTableCellNode(
      headerState,
      colSpan,
      width,
      undefined,
      rowSpan,
      borders,
      cellFormat
    )
  );
}
