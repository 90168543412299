import { TextNode } from "lexical";

/**
 * @typedef {ReturnType<InlineNode["exportJSON"]>} SerializedInlineNode
 */

export class InlineNode extends TextNode {
  /** @type {*} The inline SFDT metadata. */
  __sfdtInlineMetadata;

  static getType() {
    return "inline";
  }

  /**
   * @param {InlineNode} node
   * @returns {InlineNode}
   */
  static clone(node) {
    const inlineNode = new InlineNode(node.__sfdtInlineMetadata, node.__key);
    return inlineNode;
  }

  /**
   * @param {*} sfdtInlineMetadata
   * @param {string} [key]
   */
  constructor(sfdtInlineMetadata, key) {
    super("", key);
    this.__sfdtInlineMetadata = sfdtInlineMetadata;
  }

  /**
   * @param {EditorConfig} config
   */
  createDOM(config) {
    const element = super.createDOM(config);
    const what = this.getSfdtInlineMetadata();

    if (what.breakClearType === "All") {
      const br = document.createElement("br");
      element.replaceWith(br);
      // element.style.display = "block";
    }

    return element;
  }

  updateDOM() {
    // Needed to update styles from the toolbar.
    return true;
  }

  /**
   * @returns {DOMConversionMap | null}
   */
  importDOM() {
    return null;
  }

  /**
   * @param {LexicalEditor} editor
   * @returns {DOMExportOutput}
   */
  exportDOM(editor) {
    const domExportOutput = super.exportDOM(editor);
    return domExportOutput;
  }

  /**
   * @param {*} sfdtInlineMetadata
   */
  setSfdtInlineMetadata(sfdtInlineMetadata) {
    const self = this.getWritable();
    self.__sfdtInlineMetadata = sfdtInlineMetadata;
  }

  getSfdtInlineMetadata() {
    const self = this.getLatest();
    return self.__sfdtInlineMetadata;
  }

  /**
   * @param {SerializedInlineNode} serializedNode
   * @returns {InlineNode}
   */
  static importJSON(serializedNode) {
    const node = $createInlineNode(serializedNode.sfdtInlineMetadata);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON() {
    const serializedNode = {
      ...super.exportJSON(),
      type: InlineNode.getType(),
      sfdtInlineMetadata: this.getSfdtInlineMetadata(),
      version: 1,
    };
    return serializedNode;
  }
}

/**
 * @param {*} sfdtInlineMetadata
 * @returns {InlineNode}
 */
export function $createInlineNode(sfdtInlineMetadata) {
  const inlineNode = new InlineNode(sfdtInlineMetadata);
  return inlineNode;
}

/**
 * @param {LexicalNode | null | undefined} node
 * @returns {node is InlineNode}
 */
export function $isInlineNode(node) {
  const isInlineNode = node instanceof InlineNode;
  return isInlineNode;
}
