/** @import { List, AbstractList } from "../../types/sfdt" */

/**
 * Returns the abstract list for the corresponding `listId`.
 *
 * @param {List[]} lists
 * @param {AbstractList[]} abstractLists
 * @param {number | undefined} listId
 * @throws {Error}
 * @returns {AbstractList | null}
 */
export function getAbstractListByListId(
  lists = [],
  abstractLists = [],
  listId
) {
  if (typeof listId !== "number") {
    console.warn("List identifier is undefined.");
    return null;
  }

  // The listId can range from -1 to any positive integer. -1 means that the block exists as part of the context
  // of a list but does not have a marker and should not be taken into account for list numbering calculations.
  if (listId === -1) return null;

  const list = lists.find((l) => l.listId === listId);
  if (!list) {
    console.warn(`List with listId ${listId} does not exist in the SFDT.`);
    return null;
  }

  const abstractList = abstractLists.find(
    (al) => al.abstractListId === list.abstractListId
  );
  if (!abstractList) {
    console.warn(
      `Abstract list with abstractListId ${list.abstractListId} does not exist in the SFDT.`
    );
    return null;
  }

  return abstractList;
}
