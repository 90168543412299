/**
 * @typedef {object} TaskStatusStyles
 * @property {string} [backgroundColor]
 * @property {string} [color]
 * @property {string} [border]
 */

/**
 * @param {import("./constants").TaskStatus} taskStatus
 * @returns {TaskStatusStyles}
 */
export function getStatusTaskStyles(taskStatus) {
  /** @type {TaskStatusStyles} */
  const styles = {};

  switch (taskStatus) {
    case "Pending": {
      styles.backgroundColor = "#ECECEC";
      styles.color = "#5B5B5B";
      break;
    }

    case "Assigned": {
      styles.backgroundColor = "#C99AFF";
      styles.color = "#FFFFFF";
      break;
    }

    case "In Progress": {
      styles.backgroundColor = "#6200EE";
      styles.color = "#FFFFFF";
      break;
    }

    case "Completed": {
      styles.backgroundColor = "#00933B";
      styles.color = "#FFFFFF";
      break;
    }

    case "Approved": {
      styles.backgroundColor = "#00933B";
      styles.color = "#FFFFFF";
      break;
    }

    case "Cancelled": {
      styles.backgroundColor = "#FFFFFF";
      styles.color = "#979797";
      styles.border = "1px solid #979797";
      break;
    }

    default: {
      break;
    }
  }

  return styles;
}
