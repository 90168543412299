import { $getListDepth, ListNode } from "@lexical/list";
import { $dfs } from "@lexical/utils";
import {
  $applyNodeReplacement,
  $createTextNode,
  $isElementNode,
} from "lexical";
import { TYPE_LIST_NODE } from "../utils/constants";
import {
  $createCustomListItemNode,
  $isCustomListItemNode,
} from "./CustomListItemNode";
import { removeRevisionIdsFromCharacterFormat } from "./utils/removeRevisionIdsFromCharacterFormat";

/**
 * @typedef {object} ParagraphFormat
 * @property {number} leftIndent
 * @property {number} firstLineIndent
 * @property {number} beforeSpacing
 * @property {number} afterSpacing
 * @property {number} lineSpacing
 * @property {"Multiple" | "Exactly" | "Double" | "AtLeast" } lineSpacingType
 * @property {string} outlineLevel
 * @property {object} listFormat
 * @property {number} listFormat.listLevelNumber
 * @property {number} listFormat.listId
 * @property {number} listFormat.nsid
 * @property {boolean} keepWithNext
 * @property {boolean} keepLinesTogether
 * @property {string} styleName
 */

export class CustomListNode extends ListNode {
  /** @type {string?} does this list item has a marker */ __hasMarker;

  /** @type {string?} this is the format of the marker (e.g., %1.) */ __marker;

  /** @type {MarkerPattern} this is the type of the format */ __markerPattern;

  /** @type {number} sfdt list id */ __listId;

  /** @type {string} style string */ __styles;

  /** @type {Partial<ParagraphFormat>} */ __paragraphFormat = {};

  /** @private @type {import("../types/sfdt").CharacterFormat | undefined} */ __characterFormat;

  static getType() {
    return TYPE_LIST_NODE;
  }

  /**
   * @param {*} listId
   * @param {*} listType
   * @param {*} start
   * @param {*} key
   * @param {*} hasMarker
   * @param {*} marker
   * @param {*} markerPattern
   * @param {*} styles
   */
  constructor(
    listId,
    listType,
    start,
    key,
    hasMarker = true,
    marker = "",
    markerPattern = "",
    styles = ""
  ) {
    super(listType, start, key);

    this.__listId = listId;
    this.__hasMarker = hasMarker;
    this.__marker = marker;
    this.__markerPattern = markerPattern;
    this.__styles = styles;
    this.__levels = {};
  }

  getStyles() {
    const self = this.getLatest();
    return self.__styles;
  }

  /**
   * @param {string} styles
   */
  setStyles(styles) {
    const self = this.getWritable();
    self.__styles = styles;
    return this;
  }

  getListId() {
    const self = this.getLatest();
    return self.__listId;
  }

  /**
   * @param {number} listId
   */
  setListId(listId) {
    const self = this.getWritable();
    self.__listId = listId;
  }

  getMarker() {
    const self = this.getLatest();
    return self.__marker;
  }

  getMarkerPattern() {
    const self = this.getLatest();
    return self.__markerPattern;
  }

  getHasMarker() {
    const self = this.getLatest();
    return self.__hasMarker;
  }

  /**
   * @param {string | null} marker
   * @param {string} markerPattern
   */
  setMarker(marker, markerPattern) {
    const self = this.getWritable();
    if (marker) self.__marker = marker;
    // @ts-ignore
    if (markerPattern) self.__markerPattern = markerPattern;
    return this;
  }

  /**
   * @param {string | null} hasMarker
   */
  setHasMarker(hasMarker) {
    const self = this.getWritable();
    self.__hasMarker = hasMarker;
  }

  getLevels() {
    const self = this.getLatest();
    return self.__levels;
  }

  /**
   * @param {{}} levels
   */
  setLevels(levels) {
    const self = this.getWritable();
    self.__levels = levels;
    return this;
  }

  /**
   * @param {string | number} index
   * @param {any} level
   */
  setLevel(index, level) {
    const self = this.getWritable();
    // @ts-ignore
    self.__levels[index] = level;
    return this;
  }

  /**
   * @param {number} start
   */
  setStart(start) {
    const self = this.getWritable();
    self.__start = start;
    return this;
  }

  /**
   * @returns {Partial<ParagraphFormat>}
   */
  getParagraphFormat() {
    const self = this.getLatest();
    return self.__paragraphFormat;
  }

  /**
   * @param {*} paragraphFormat
   */
  setParagraphFormat(paragraphFormat) {
    const self = this.getWritable();
    self.__paragraphFormat = paragraphFormat;
    return this;
  }

  /**
   * @returns {typeof this.__characterFormat}
   */
  getCharacterFormat() {
    const self = this.getLatest();
    return self.__characterFormat;
  }

  /**
   * @param {typeof this.__characterFormat} characterFormat
   */
  setCharacterFormat(characterFormat) {
    const self = this.getWritable();
    self.__characterFormat =
      removeRevisionIdsFromCharacterFormat(characterFormat);
    return this;
  }

  /**
   * @param {ListNode} prevNode
   */
  updateDOM(prevNode) {
    const prevDepth = $getListDepth(prevNode),
      newDepth = $getListDepth(this);
    return prevDepth !== newDepth || prevNode.__start !== this.__start;
  }

  /**
   * @param {{ disableEvents: boolean | undefined; namespace: string; theme: import("lexical").EditorThemeClasses; }} config
   */
  createDOM(config) {
    const listHtmlElement = super.createDOM(config);

    /**
     * @type {Partial<ParagraphFormat> | undefined }
     */
    const paragraphFormat = this.__paragraphFormat;
    if (!paragraphFormat) return listHtmlElement;

    // if (paragraphFormat.leftIndent) {
    // const listItemIndentation =
    //   (paragraphFormat.leftIndent || 0) +
    //   (paragraphFormat.firstLineIndent || 0);

    // listHtmlElement.style.setProperty(
    //   "--list-item-indentation", // Variable defined in index.css.
    //   `${listItemIndentation}px`
    // );

    listHtmlElement.style.setProperty(
      "--list-item-marker-gap", // Variable defined in index.css.
      `${Math.abs(paragraphFormat?.firstLineIndent || 0)}px`
    );
    // }

    if (paragraphFormat?.beforeSpacing) {
      listHtmlElement.style.marginTop = `${paragraphFormat.beforeSpacing}px`;
    }

    if (paragraphFormat?.afterSpacing) {
      listHtmlElement.style.marginBottom = `${paragraphFormat.afterSpacing}px`;
    }

    // @ts-ignore
    if (paragraphFormat?.lineSpacing) {
      // @ts-ignore
      const lineSpacing = paragraphFormat?.lineSpacing;
      // @ts-ignore
      const lineSpacingType = paragraphFormat?.lineSpacingType;

      switch (lineSpacingType) {
        case "AtLeast": {
          // We do not care about this.
          break;
        }
        case "Exactly":
          listHtmlElement.style.lineHeight = `${lineSpacing.toPrecision(2)}px`;
          break;
        case "Multiple":
        default:
          // Arbitrarily adding 0.5 so that the line spacing looks closer to Word.
          // @ts-ignore
          listHtmlElement.style.lineHeight = lineSpacing + 0.5;
          break;
      }
    }

    return listHtmlElement;
  }

  /**
   * @param {(import("lexical").LexicalNode | import("./CustomListItemNode").CustomListItemNode | import("./MarkNode").MarkNode)[]} nodesToAppend
   */
  append(...nodesToAppend) {
    for (let i = 0; i < nodesToAppend.length; i++) {
      const currentNode = nodesToAppend[i];

      if ($isCustomListItemNode(currentNode)) {
        this.splice(this.getChildrenSize(), 0, [currentNode]);
      } else {
        const listItemNode = $createCustomListItemNode();

        // @ts-ignore
        if ($isCustomListNode(currentNode)) {
          listItemNode.append(currentNode);
        } else if ($isElementNode(currentNode)) {
          const textNode = $createTextNode(currentNode.getTextContent());
          listItemNode.append(textNode);
        } else {
          listItemNode.append(currentNode);
        }
        this.splice(this.getChildrenSize(), 0, [listItemNode]);
      }
    }
    // updateChildrenListItemValue(this);
    return this;
  }

  /**
   *
   * @param {number} level - Starting at 0
   * @returns
   */
  getChildrenAtLevel(level) {
    const dfs = $dfs(this).filter(
      ({ node }) =>
        // @ts-ignore
        $isCustomListItemNode(node) && $getListDepth(node.getParent()) === level
    );
    return dfs.flatMap((el) => el.node);
  }

  /** @param {CustomListNode} node */
  static clone(node) {
    const newNode = new CustomListNode(
      node.__listId,
      node.__listType,
      node.__start,
      node.__key,
      node.__hasMarker,
      node.__marker,
      node.__markerPattern,
      node.__styles
    );

    newNode.__levels = node.__levels;
    newNode.__paragraphFormat = node.__paragraphFormat;
    newNode.__characterFormat = node.__characterFormat;

    return newNode;
  }

  /**
   *
   * @param {SerializedCustomListNode} serializedNode
   * @returns
   */
  static importJSON(serializedNode) {
    const node = new CustomListNode(
      // @ts-ignore
      serializedNode.listId,
      serializedNode.listType,
      serializedNode.start,
      // @ts-ignore
      serializedNode.key,
      // @ts-ignore
      serializedNode.hasMarker,
      // @ts-ignore
      serializedNode.marker,
      // @ts-ignore
      serializedNode.markerPattern,
      // @ts-ignore
      serializedNode.styles
    );
    // @ts-ignore
    node.__levels = serializedNode.levels;
    // @ts-ignore
    node.__paragraphFormat = serializedNode.paragraphFormat || {};
    node.__characterFormat = removeRevisionIdsFromCharacterFormat(
      serializedNode.characterFormat
    );

    return node;
  }

  /**
   * @typedef {ReturnType<typeof this.exportJSON>} SerializedCustomListNode
   */
  exportJSON() {
    return {
      ...super.exportJSON(),
      listId: this.__listId,
      marker: this.__marker,
      markerPattern: this.__markerPattern,
      hasMarker: this.__hasMarker,
      styles: this.__styles,
      levels: this.__levels,
      type: TYPE_LIST_NODE,
      paragraphFormat: this.__paragraphFormat,
      characterFormat: removeRevisionIdsFromCharacterFormat(
        this.__characterFormat
      ),
      version: 1,
    };
  }
}

/**
 * @param {*} hasMarker
 * @returns {CustomListNode}
 * @param {string | number | undefined} listId
 * @param {string} listType
 */
export function $createCustomListNode(
  listId,
  listType,
  start = 1,
  key = undefined,
  hasMarker = true
) {
  return $applyNodeReplacement(
    new CustomListNode(listId, listType, start, key, hasMarker)
  );
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @returns {node is CustomListNode}
 */
export function $isCustomListNode(node) {
  return node instanceof CustomListNode;
}
