/** @import { Sfdt, ParagraphFormat, Block, ListFormat, CharacterFormat } from "../../types/sfdt" */

import { getAbstractListByListId } from "./getAbstractListFromListId";

/**
 * Returns the list format character format from the paragraph format regardless if it is defined directly on the
 * paragraph format or in the style. This character format **SHOULD ONLY BE USED** to style the list marker.
 *
 * @param {Sfdt} sfdt
 * @param {Block} block
 * @returns {CharacterFormat | null}
 */
export function getListFormatCharacterFormatFromParagraphFormat(sfdt, block) {
  if (!block.paragraphFormat) {
    return { ...sfdt.characterFormat, ...block.characterFormat };
  }

  const listFormat = block.paragraphFormat.listFormat;
  if (listFormat) {
    const listCharacterFormat = getCharacterFormatFromListFormat(
      sfdt,
      listFormat
    );
    return {
      ...sfdt.characterFormat,
      ...block.characterFormat,
      ...listCharacterFormat,
    };
  }

  const styleName = block.paragraphFormat.styleName;
  if (styleName) {
    const style = sfdt.styles.find((s) => s.name === styleName);
    if (!style) return null;
    if (style.paragraphFormat && style.paragraphFormat.listFormat) {
      const listFormat = style.paragraphFormat.listFormat;
      if (!listFormat) return null;

      const listCharacterFormat = getCharacterFormatFromListFormat(
        sfdt,
        listFormat
      );
      return {
        ...sfdt.characterFormat,
        ...block.characterFormat,
        ...listCharacterFormat,
      };
    }
  }

  return null;
}

/**
 * @param {Sfdt} sfdt
 * @param {ListFormat} listFormat
 * @returns {CharacterFormat | null}
 */
function getCharacterFormatFromListFormat(sfdt, listFormat) {
  const abstractList = getAbstractListByListId(
    sfdt.lists,
    sfdt.abstractLists,
    listFormat.listId
  );
  if (!abstractList) return null;

  const level = abstractList.levels[listFormat.listLevelNumber];
  if (!level) return null;

  const characterFormat = level.characterFormat;
  return characterFormat;
}

/**
 * @param {Sfdt} sfdt
 * @param {string} styleName
 * @returns {ListFormat | null}
 */
function findListFormatFromStylesRecursively(sfdt, styleName) {
  const style = sfdt.styles.find((s) => s.name === styleName);
  if (!style) return null;

  // Check if the style has a listFormat.
  if (style.paragraphFormat && style.paragraphFormat.listFormat) {
    const listFormat = style.paragraphFormat.listFormat;
    return listFormat;
  }

  // If there's a "basedOn", recursively check the parent style.
  if (style.basedOn) {
    const listFormat = findListFormatFromStylesRecursively(sfdt, style.basedOn);
    return listFormat;
  }

  // No listFormat found in this style or its hierarchy.
  return null;
}
