import { $isCustomListItemNode } from "../../nodes/CustomListItemNode";

/**
 * @param {ReturnType<import("@lexical/utils").$dfs>} dfs
 */
export function getListItemsFromDfs(dfs) {
  const listItemNodes = dfs.filter(({ node }) => $isCustomListItemNode(node));

  const listItems = listItemNodes.map(({ node }) => {
    return {
      level: node.getIndent(),
      id: node.getKey(),
      value: node.getValue(),
      listId: node.getListId(),
      parentId: "", // This is filled later on.
    };
  });

  return listItems;
}
