import { $getNearestNodeOfType } from "@lexical/utils";
import { $createTextNode } from "lexical";
import {
  $createCustomListItemNode,
  CustomListItemNode,
} from "../../nodes/CustomListItemNode";
import {
  $createCustomListNode,
  $isCustomListNode,
} from "../../nodes/CustomListNode";
import { $isImageNode } from "../../nodes/ImageNode";
import { GlobalListHandler } from "../../plugins/ListExtendedPlugin/GlobalListHandler";
import {
  getIndentationStyles,
  getListLevelNumber,
} from "../../plugins/ListExtendedPlugin/utils";
import { getAbstractListByListId } from "../invariants/getAbstractListFromListId";
import {
  convertBlockStylesToCSS,
  parseInlines,
  parseTextNodeFormat,
} from "./text";

/** This method generates lists
 * Receives a clause node to append the created nodes onto
 * TODO: TOO many arguments, need to simplify this whenever I have time
 *
 * @param {number | undefined} lid
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../nodes").ClauseNode} clauseNode
 * @param {import("../../types/sfdt").Block} block
 * @param {import("./text").ComplementaryData} complementaryData
 * @param {import("../../types/sfdt").Section} section
 * @param {import("../../types/sfdt").Style | undefined} style
 * @param {string} blockType
 * @param {listInfo[]} addedLists
 * @param {listInfo} listInfo
 * @param {import("@lexical/rich-text").HeadingNode | undefined} heading
 * @param {boolean} isNoMarker
 * @param {boolean} isBulleted
 * @param {*[]} organizationUsers
 * @returns {import("../../nodes").CustomListNode | false}
 */
export const generateListNode = (
  lid,
  sfdt,
  clauseNode,
  block,
  complementaryData,
  section,
  style,
  blockType,
  addedLists,
  listInfo,
  heading,
  isNoMarker = false,
  isBulleted = false,
  organizationUsers
) => {
  const listHandler = GlobalListHandler.getInstance();
  const currentListId = isNoMarker ? -1 : lid;
  // TODO: Double-check if we are handling abstract lists/lists correctly.
  const existingList = addedLists.find((li) => li.listId === currentListId);

  const { resultingList, listItemValue } = createOrUpdateListNode(
    lid,
    sfdt,
    block,
    complementaryData,
    section,
    organizationUsers,
    style,
    blockType,
    existingList,
    heading,
    isNoMarker,
    isBulleted
  );
  if (!resultingList || !listItemValue) {
    return false;
  }

  //at this point we have the previous list with a new listitemnode
  // or we have a new list with a new listitemnode
  //before setting we need to consider indentation level
  //  List
  //    listItemNode
  //      textNode
  // get level of indentation to set
  /** @type {number | undefined} */
  const level = getListLevelNumber(block, style);
  const isListNode = $isCustomListNode(resultingList);
  // @ts-ignore
  const parentList = isListNode ? resultingList : resultingList.getParent();
  if (parentList) {
    //persist current list info
    /** @type {listInfo} */
    listInfo.key = parentList?.getKey();
    listInfo.listId = currentListId;
    listInfo.clauseKey = clauseNode.getKey();
    if (!addedLists.find((al) => al.listId === existingList?.listId)) {
      const newListInfo = {
        key: parentList?.getKey(),
        listId: currentListId,
        clauseKey: clauseNode.getKey(),
      };
      addedLists.push(newListInfo);
    }

    let paragraphFormat;
    // If paragraph format exists on both the block and SFDT we merge them,
    // giving priority to the paragraph format from the SFDT.
    if (style?.paragraphFormat && block.paragraphFormat) {
      paragraphFormat = {
        ...style.paragraphFormat,
        ...block.paragraphFormat,
      };
    } else {
      paragraphFormat = style?.paragraphFormat || {};
    }
    parentList.setParagraphFormat(paragraphFormat);

    clauseNode.append(parentList);
  }
  // set indentation
  if (level !== undefined) {
    //first time for this list
    //is list node
    //is new list
    // @ts-ignore
    const listItemNode = resultingList.getFirstChild();
    if (listItemNode) {
      const absList = getAbstractListByListId(
        sfdt?.lists,
        sfdt?.abstractLists,
        currentListId
      );
      if (absList) {
        const structLevel = absList.levels[level];
        resultingList.setMarker(
          // @ts-ignore
          structLevel.numberFormat,
          structLevel.listLevelPattern
        );
      }
      listItemNode.setIndent(level);
      listHandler.root.push({
        id: listItemNode.getKey(),
        level: level,
        parentId:
          GlobalListHandler.getReversedRoot().find(
            (it) => it.level === level - 1
          )?.id ?? "", //TODO: acccount for leaps between levels
        // @ts-ignore
        listId: currentListId,
        value: listItemValue,
      });
      // console.log(listHandler.root);
      //this is the first node of the list
      //we can withdraw styles from this one for templating on the list
    }
    //created 1 level
    // listNode >>>>
    //      List (listNode)
    //        listItemNode (listNode > listItemNode)
    // NEW LEVEL - NEW LEVEL - NEW LEVEL
    //          List (l)
    //            listItemNode (li)
    // NEW LEVEL - NEW LEVEL - NEW LEVEL
    //              textNode (listNode > listItemNode > textNode)
    // @ts-ignore
    $getNearestNodeOfType(
      // @ts-ignore
      resultingList.getLastDescendant(),
      CustomListItemNode
    ).setValue(listItemValue);
  }
  return parentList;
};

/** Returns list if it's first time creating list
 * Returns list item node if not
 * @typedef {Object} CreateListObject
 * @property {import("../../nodes").CustomListNode} resultingList
 * @property {number} listItemValue
 *
 * @param {number | undefined} currentListId
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Block} block
 * @param {import("./text").ComplementaryData} complementaryData
 * @param {import("../../types/sfdt").Section} section
 * @param {*[]} organizationUsers
 * @param {import("../../types/sfdt").Style} [style]
 * @param {string} blockType
 * @param {listInfo} [existingList]
 * @param {import("@lexical/rich-text").HeadingNode} [heading]
 * @param {boolean} [isNoMarker]
 * @return {CreateListObject}
 */
export const createOrUpdateListNode = (
  currentListId,
  sfdt,
  block,
  complementaryData,
  section,
  organizationUsers,
  style,
  // @ts-ignore
  blockType,
  existingList,
  // @ts-ignore
  heading = false,
  isNoMarker = false,
  /** @type {boolean} */ isBulleted
) => {
  const listHandler = GlobalListHandler.getInstance();
  /** @type {import("lexical").LexicalNode[]} */
  const textNodes = [];
  // SFDT inlines are equivalent to fragments of Lexical paragraphs.
  const { inlines } = block;

  if (inlines && inlines.length) {
    // Iterate inlines.
    parseInlines(
      block,
      inlines,
      textNodes,
      sfdt,
      complementaryData,
      section,
      organizationUsers,
      blockType
    );
  }

  //move non-inline images to end of array
  /** @type {import("lexical").LexicalNode[]} */
  const nodesInOrder = [];
  /** @type {import("lexical").LexicalNode[]} */
  const cloneFrags = textNodes;
  cloneFrags.forEach((el) => {
    if ($isImageNode(el) && !el.__isInline) {
      //@ts-ignore
      nodesInOrder.push(...textNodes.splice(textNodes.indexOf(el), 1));
    }
  });
  textNodes.push(...nodesInOrder);
  // if no text nodes on listitemnode
  // need to create text node with text length > 1
  // else it won't render and throw an error
  let textNodesToAdd;
  /** @type {import("../../nodes").CustomListItemNode} */
  let listItemNode; //listItemNode to attach
  let listValue = 1; // value for listitemnode
  const listLevelNumber =
    block.paragraphFormat?.listFormat?.listLevelNumber ??
    // @ts-ignore
    style.paragraphFormat?.listFormat?.listLevelNumber ??
    0; //listlevelnumber from list

  const abstractList = getAbstractListByListId(
    sfdt?.lists,
    sfdt?.abstractLists,
    currentListId
  );

  /** @type {import("../../types/sfdt").AbstractListLevel} */
  // @ts-ignore
  const level = abstractList?.levels[listLevelNumber]; //first level
  if (currentListId !== -1 && (!abstractList || !level)) {
    // @ts-ignore
    return false;
  }

  const lastItem = listHandler.root[listHandler.root.length - 1];
  const reversedRoot = GlobalListHandler.getReversedRoot();
  //Calculate list item value
  if (lastItem && lastItem.listId === currentListId) {
    //if there's a list before this one
    //and it's same list
    let previousListItemAtSameLevel;
    //  Note: I don't need parentId here so no need
    //        to keep the loop going after finding value
    for (const item of reversedRoot) {
      if (item.listId === currentListId) {
        if (item.level <= listLevelNumber - 1) {
          break;
        } else if (item.level === listLevelNumber) {
          previousListItemAtSameLevel = item;
          break;
        }
      }
      continue;
    }
    const continueNumbering = true; //unsure how to know if should continue or not
    if (previousListItemAtSameLevel && continueNumbering) {
      //and should continue numbering
      listValue = previousListItemAtSameLevel.value + 1;
    }
    //else val it's 1
  } else if (existingList) {
    //if there's no list before or it's not same list and
    //it's not same list
    //but list exists somewhere
    let previousListItemAtSameLevel;
    //  Note: I don't need parentId here so no need
    //        to keep the loop going after finding value
    for (const item of reversedRoot) {
      if (item.listId === currentListId) {
        if (item.level <= listLevelNumber - 1) {
          break;
        } else if (item.level === listLevelNumber) {
          previousListItemAtSameLevel = item;
          break;
        }
      }
      continue;
    }
    if (previousListItemAtSameLevel) {
      listValue = previousListItemAtSameLevel.value + 1;
    }
    //else val it's 1
  } else {
    //it doesn't exist so val it's 1
  }

  let listItemNodeStyles = "",
    listItemNodePaddingStyles = "";

  // TODO: This logic should be done in a better way taking the other text alignments into account.
  // Also, we need to take this into account during the export.
  if (block?.paragraphFormat?.textAlignment === "Justify") {
    listItemNodeStyles += "text-align: justify; text-justify: inter-word;";
  }

  //List item node padding styles based on block
  // @ts-ignore
  const { left, right } = getIndentationStyles(block, null, null);
  if (left) listItemNodePaddingStyles += `padding-left: ${left}px;`;
  if (right) listItemNodePaddingStyles += `padding-right: ${right}px;`;
  if (heading) {
    textNodesToAdd = [heading];
    const formatFromHeadingTextNode = parseTextNodeFormat(
      // @ts-ignore
      heading.getFirstChild()
    );
    // @ts-ignore
    const stylesFromHeading = heading.getFirstChild().getStyle();
    listItemNodeStyles += `${formatFromHeadingTextNode}${stylesFromHeading}`;
    //since it's a heading we need to create listitem node and append the heading
    //no marker lists
    listItemNode = $createCustomListItemNode(
      !isNoMarker,
      listValue,
      listItemNodeStyles,
      "",
      blockType
    ).append(...textNodesToAdd);
  } else {
    //block contains styles
    const blockStyles = convertBlockStylesToCSS(sfdt, block);
    listItemNodeStyles += blockStyles;

    textNodesToAdd = textNodes.length === 0 ? [$createTextNode("")] : textNodes;
    listItemNode = $createCustomListItemNode(
      !isNoMarker,
      listValue,
      listItemNodeStyles,
      "",
      blockType
    ).append(...textNodesToAdd);
  }

  // After creating the node with initial styles and content We need to add it to either a new list or an existing
  // one we also need to set some extra styling to both list and list item and add a value.

  //remove checked due to bug
  delete listItemNode.__checked;
  // @ts-ignore
  listItemNode.setListId(currentListId);
  //we always create a new list for a new clause
  const customListNode = $createCustomListNode(
    currentListId,
    !isBulleted ? "number" : "bullet"
  ).append(listItemNode);
  // @ts-ignore
  if (block.paragraphFormat.textAlignment) {
    // @ts-ignore
    const textAlignment = block.paragraphFormat.textAlignment.toLowerCase();
    // @ts-ignore
    customListNode.setFormat(textAlignment);
  }
  if (level) {
    let levelStyles = "",
      levelPaddingStyles = "";

    //block contains styles
    levelStyles = convertBlockStylesToCSS(sfdt, level);

    //Level padding styles based on level
    // @ts-ignore
    const { left, right } = getIndentationStyles(null, null, level);
    if (left) levelPaddingStyles += `padding-left: ${left}px;`;
    if (right) levelPaddingStyles += `padding-right: ${right}px;`;

    if (listItemNodePaddingStyles) {
      // @ts-ignore
      abstractList.levels[listLevelNumber].blockPaddingStyles =
        listItemNodePaddingStyles;
    }
    if (levelStyles) {
      //if listitemnode has extrastyles (actual text)
      // @ts-ignore
      abstractList.levels[listLevelNumber].blockStyles = levelStyles; //these apply to marker as well
    }
    if (levelPaddingStyles) {
      // @ts-ignore
      abstractList.levels[listLevelNumber].paddingStyles = levelPaddingStyles;
    }
    //if it has marker icon/format/number... set it
    if (currentListId !== -1) {
      customListNode.setMarker(
        // @ts-ignore
        level.numberFormat,
        level.listLevelPattern
      );
    }
  }
  return { resultingList: customListNode, listItemValue: listValue };
};
