import { faPen, faTimes, faTrashXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalStore } from "../../state/store";

//SHOULD BE GLOBAL
const Loading = (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      my: 20,
    }}
  >
    <CircularProgress size={24} />
  </Box>
);

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function DialogManageRoles({ currentRole, closeDialog, open }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const navigate = useNavigate();

  const [loading] = useState(false);
  const [userRole] = useState(state.user.role?.name);
  const [localRole, setLocalRole] = useState(currentRole);

  const [dirtyLocalRole, setDirtyLocalRole] = useState(currentRole);
  const [confirmRoleDelete, setConfirmRoleDelete] = useState(false);
  const [nonDeletableRoles] = useState(["Admin", "Legal"]);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    description: false,
    hasReadOnly: false,
    description2: false,
  });

  const validate = useCallback(() => {
    let errors = {};
    if (!dirtyLocalRole.name.length > 0) {
      errors.name = "This field is required.";
    }
    if (!dirtyLocalRole.description.length > 0) {
      errors.description = "This field is required.";
    }
    if (dirtyLocalRole.hasReadOnly && !dirtyLocalRole.description2.length > 0) {
      errors.description2 = "This field is required.";
    }
    if (Object.keys(errors).length > 0) {
      return {
        errors: errors,
        isValid: false,
      };
    }
    return {
      isValid: true,
    };
  }, [dirtyLocalRole]);

  useEffect(() => {
    if (open) {
      setLocalRole(currentRole);
      setDirtyLocalRole(currentRole);
    }
  }, [currentRole, open]);

  useEffect(
    () => {
      const original = Object.keys(localRole);
      const dirty = Object.keys(dirtyLocalRole);
      if (original.length !== dirty.length) {
        return;
      }

      let shouldUpdate = false;
      for (let key of original) {
        if (localRole[key] !== dirtyLocalRole[key]) {
          shouldUpdate = true;
          break;
        }
      }
      setNeedsUpdate(shouldUpdate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirtyLocalRole]
  );

  /**
   * @param {*} field
   * @param {*} newValue
   */
  const changeRole = (field, newValue) => {
    setDirtyLocalRole((/** @type {*} */ s) => ({ ...s, [field]: newValue }));
  };

  const cancel = useCallback(() => {
    if (confirmRoleDelete) {
      setConfirmRoleDelete(false);
    } else {
      resetDialog();
      closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmRoleDelete, closeDialog]);

  const close = useCallback(
    (action, message) => {
      resetDialog();
      closeDialog(action, message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDialog]
  );

  const resetDialog = useCallback(() => {
    const initialRole = {
      name: "",
      description: "",
      hasReadOnly: false,
      noSending: false,
      description2: "",
      isPrivileged: false,
      allAreas: false,
      selectedAreaLabels: [],
    };
    setErrors(initialRole);
    setLocalRole(initialRole);
    setDirtyLocalRole(initialRole);
    setConfirmRoleDelete(false);
  }, []);

  const updateRole = useCallback(
    function () {
      const validation = validate();
      if (!validation.isValid) {
        setErrors(validation.errors);
      } else {
        //remove second description if value hasReadOnly === false
        let roleToSend = Object.assign({}, dirtyLocalRole);
        if (!roleToSend.hasReadOnly) {
          roleToSend.description2 = "";
        }
        Object.keys(localRole).forEach((k) => {
          if (localRole[k] === roleToSend[k]) {
            delete roleToSend[k];
          } else if (k === "description2") {
            if (roleToSend.hasReadOnly === false) {
              roleToSend.description2 = "";
            }
          }
        });

        axios
          .put(`${state.settings.api}role/${localRole._id}`, {
            role: {
              ...roleToSend,
            },
          })
          .then(() => {
            close("roleUpdated");
          })
          .catch((error) => {
            console.error(error);
            if (
              typeof error.response.data.message === "string" &&
              error.response.data.message === "Non-unique name for role."
            ) {
              setErrors((previous) => ({
                ...previous,
                name: "A role with this name already exists. Please try a different one.",
              }));
            } else {
              dispatch({
                type: "NEW_SNACKBAR",
                payload: {
                  message:
                    "An error occurred while updating the role, try again or contact Canveo Support if the issue persists.",
                  severity: "error",
                },
              });

              // close("error", error.response.data.message);
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localRole, dirtyLocalRole]
  );

  const deleteRole = useCallback(
    function () {
      axios
        .put(`${state.settings.api}role/delete/${localRole._id}`)
        .then(() => {
          close("roleDeleted");
        })
        .catch((err) => {
          close("error", err.response.data.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localRole]
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      {!confirmRoleDelete && (
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={close}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
      )}
      <DialogTitle>
        {loading ? "Loading ..." : `Manage Role (${localRole.name})`}
      </DialogTitle>

      {!confirmRoleDelete && (
        <DialogContent>
          {loading ? (
            Loading
          ) : (
            <Box
              sx={{
                py: 3,
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                fullWidth
                multiline
                disabled={nonDeletableRoles.includes(localRole.name)}
                helperText={
                  nonDeletableRoles.includes(localRole.name)
                    ? "This is an out-of-the-box, privileged role and can't be changed."
                    : errors.name ?? ""
                }
                error={!!errors.name}
                value={dirtyLocalRole.name}
                onInput={(e) => changeRole("name", e.target.value)}
                label="Role Name"
                placeholder="Role name (e.g. Sales)"
              />

              <TextField
                fullWidth
                multiline
                disabled={nonDeletableRoles.includes(localRole.name)}
                helperText={errors.description ?? ""}
                error={!!errors.description}
                value={dirtyLocalRole.description}
                onInput={(e) => changeRole("description", e.target.value)}
                label="Role Description"
                placeholder='E.g. Can access contracts with the label "Customers"'
              />

              <Grid container sx={{ marginTop: 4 }}>
                This role can access contracts in the following areas:
              </Grid>

              <Grid container>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={dirtyLocalRole.isPrivileged}
                        checked={dirtyLocalRole.allAreas}
                        onChange={(_event, checked) => {
                          changeRole("allAreas", checked);
                          if (checked) {
                            changeRole("selectedAreaLabels", []);
                          }
                        }}
                      />
                    }
                    label="All areas"
                  />
                </FormGroup>
              </Grid>

              <Grid container>
                <Autocomplete
                  disabled={
                    dirtyLocalRole.isPrivileged || dirtyLocalRole.allAreas
                  }
                  multiple
                  fullWidth
                  value={dirtyLocalRole.selectedAreaLabels}
                  options={state.labels.filter(
                    (/** @type {{ type: { name: string; }; }} */ l) =>
                      l.type.name === "Area"
                  )}
                  onChange={(_event, value) => {
                    changeRole("selectedAreaLabels", value);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Area Labels" />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props}>
                      {option._id ? (
                        <Chip
                          key={option._id}
                          label={option.name}
                          color="primary"
                          sx={{
                            fontWeight: "700",
                            px: 1,
                          }}
                        />
                      ) : (
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {option.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        color="primary"
                        sx={{
                          fontWeight: "700",
                          px: 1,
                        }}
                        onClick={() => {}}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  filterSelectedOptions
                />
              </Grid>

              <Grid container justifyContent="right">
                <Button onClick={() => navigate("/templates/labels")}>
                  Manage Area Labels ...
                </Button>
              </Grid>

              {/* TODO: disable if there are users on the this role */}
              {/* {!["Admin", "Legal"].includes(localRole.name) &&
                getCanveoTier(state?.user?.email) === "experimental" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dirtyLocalRole.hasReadOnly}
                        onChange={(e) =>
                          changeRole("hasReadOnly", e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Has read-only option?"
                  />
                )}
              {!["Admin", "Legal"].includes(localRole.name) &&
                getCanveoTier(state?.user?.email) === "experimental" && (
                  <StyledFormHelperText sx={{ mt: 0 }}>
                    When checked, this role can also be assigned as read-only,
                    preventing users with this role from making edits to the
                    text of a contract.
                  </StyledFormHelperText>
                )} */}
              {dirtyLocalRole.hasReadOnly && (
                <TextField
                  fullWidth
                  multiline
                  helperText={errors.description2 ?? ""}
                  error={!!errors.description2}
                  value={dirtyLocalRole.description2}
                  onInput={(e) => changeRole("description2", e.target.value)}
                  label="Read-Only Role Description "
                  placeholder='E.g. Has read-only access to the contracts with the label "Customers"'
                />
              )}

              {!["Admin", "Legal"].includes(localRole.name) && (
                <>
                  <Typography variant="subtitle1">Options</Typography>

                  {/* <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dirtyLocalRole.noSending}
                          onChange={(_event, checked) =>
                            changeRole("noSending", checked)
                          }
                        />
                      }
                      label={
                        <Typography fontWeight="400">
                          Enable “No Sending” option for this role
                        </Typography>
                      }
                    />
                    <Typography ml={4}>
                      This allows to prevent individual {localRole.name} users
                      from being able to send agreements to counterparties
                      (whether for review or signature).
                    </Typography>
                  </FormGroup> */}

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dirtyLocalRole.restrictEditing}
                          onChange={(_event, checked) =>
                            changeRole("restrictEditing", checked)
                          }
                        />
                      }
                      label={
                        <Typography fontWeight="bold">
                          Restrict Editing
                        </Typography>
                      }
                    />
                    <Typography ml={4}>
                      This prevents users with this role from making changes to
                      the text of an agreement (except for Merge Fields).
                    </Typography>
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dirtyLocalRole.restrictSending}
                          onChange={(_event, checked) =>
                            changeRole("restrictSending", checked)
                          }
                        />
                      }
                      label={
                        <Typography fontWeight="bold">
                          Restrict Sending
                        </Typography>
                      }
                    />
                    <Typography ml={4}>
                      This prevents users with this role from sending agreements
                      to counterparties (whether for review or signature),
                      unless the latest version of the agreement has been
                      approved.
                    </Typography>
                  </FormGroup>
                </>
              )}
            </Box>
          )}
        </DialogContent>
      )}

      {confirmRoleDelete && (
        <DialogContent>
          <span>
            Are you sure you want to delete the role <b>{localRole.name}</b>?
          </span>
        </DialogContent>
      )}

      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={cancel}>
          Cancel
        </Button>
        {!confirmRoleDelete &&
          userRole === "Admin" && //if user role is "Admin"
          !nonDeletableRoles.includes(localRole.name) && ( //and is not updating an non deletable role like Admin
            <>
              <Button color="error" onClick={() => setConfirmRoleDelete(true)}>
                <FontAwesomeIcon icon={faTrashXmark} />
                &nbsp; Delete
              </Button>
            </>
          )}
        {confirmRoleDelete && (
          <Button color="secondary" onClick={deleteRole}>
            Confirm
          </Button>
        )}
        {!confirmRoleDelete &&
          userRole === "Admin" && ( //if user role is "Admin"
            <>
              <Button
                color="primary"
                disabled={!needsUpdate}
                onClick={updateRole}
              >
                <FontAwesomeIcon icon={faPen} />
                &nbsp;&nbsp; Update
              </Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
}
