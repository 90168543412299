import { $createTabNode } from "lexical";
import { $createCustomParagraphNode } from "../../nodes/CustomParagraphNode";
import { elementNodeBlockFormatting, parseInlines } from "./text";

/**
 * Receives an SFDT Block and returns the equivalent Lexical ParagraphNode.
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Section} section
 * @param {import("../../types/sfdt").Block} block
 * @param {string} blockType
 * @param {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @param {Set<string>} ongoingCommentsSet
 * @param {any} user - Current user information
 * @param {any[]} collabs - Current user information
 * @param {Record<string, string> } metadata
 * @param {*[]} organizationUsers
 */
const createParagraphNode = (
  sfdt,
  section,
  block,
  blockType,
  sfdtCommentsMap,
  ongoingCommentsSet,
  user = {},
  collabs = [],
  metadata,
  organizationUsers
) => {
  const defaultTabWidth = sfdt.defaultTabWidth ?? 36; // Default is 36.
  // TODO: consider "tabs" under paragraphFormat.
  const sumOfIndentation =
    (block.paragraphFormat?.firstLineIndent ?? 0) +
    (block.paragraphFormat?.leftIndent ?? 0);
  const numberOfInitialTabs = sumOfIndentation / defaultTabWidth;
  const tabs = /** @type {import("lexical").TabNode[]} */ ([]);
  for (let i = 0; i < numberOfInitialTabs; i++) {
    const tab = $createTabNode();
    tabs.push(tab);
  }

  const paragraphNode = $createCustomParagraphNode();

  let paragraphFormat;
  // If paragraph format exists on both the block and SFDT we merge them,
  // giving priority to the paragraph format from the SFDT.
  if (block.paragraphFormat && sfdt.paragraphFormat) {
    paragraphFormat = {
      ...block.paragraphFormat,
      ...sfdt.paragraphFormat,
    };
  }
  // If not we attribute in order of priority:
  //
  // - First the paragraph format on the block if it exists.
  // - Second the paragraph format on the root level of the SFDT if it exists.
  // - Third the first style on styles array on the root level of the SFDT if it exists. This
  //   is usually the default style and regularly called "Normal".
  // - Fourth an empty object.
  else {
    if (block.paragraphFormat) {
      paragraphFormat = block.paragraphFormat;
    } else if (sfdt.paragraphFormat) {
      paragraphFormat = sfdt.paragraphFormat;
    } else if (sfdt.styles?.at(0)?.paragraphFormat) {
      paragraphFormat = {
        ...sfdt.styles?.at(0)?.paragraphFormat,
        // So that we know not to include any of these styles when doing the export.
        // isDefaultStyle: true,
      };
    } else {
      paragraphFormat = {};
    }
  }
  paragraphNode.setParagraphFormat(paragraphFormat);

  const paragraphNodes = /** @type {import("lexical").LexicalNode[]} */ ([]);
  const { inlines } = block; // SFDT inlines are equivalent to fragments of Lexical paragraphs.

  if (inlines && inlines.length) {
    // Iterate inlines.
    parseInlines(
      block,
      inlines,
      paragraphNodes,
      sfdt,
      {
        ongoingCommentsSet,
        sfdtCommentsMap,
        user,
        collabs,
        metadata,
      },
      section,
      organizationUsers,
      blockType
    );
  }

  //not only block that has this info, must search style as well
  elementNodeBlockFormatting(sfdt, block, paragraphNode);

  // TODO: Not sure what this was doing, commenting for now.
  // //move non-inline images to end of array
  // /** @type {import("lexical").LexicalNode[]} */
  // const nodesInOrder = [];
  // /** @type {import("lexical").LexicalNode[]} */
  // const cloneFrags = paragraphNodes;
  // cloneFrags.forEach((el) => {
  //   if ($isImageNode(el) && !el.__isInline) {
  //     //@ts-ignore
  //     nodesInOrder.push(
  //       ...paragraphNodes.splice(paragraphNodes.indexOf(el), 1)
  //     );
  //   }
  // });
  // paragraphNodes.push(...nodesInOrder);
  paragraphNode.append(...paragraphNodes);

  return { node: paragraphNode, ongoingCommentsSet };
};

export { createParagraphNode };
