import { getAbstractListByListId } from "../../converters/invariants/getAbstractListFromListId";

/** @import { List, AbstractList } from "../../types/sfdt" */

/**
 * @param {{ level: *; id: *; value: *; parentId: *; listId: *; }[]} listItems
 * @param {List[]} lists
 * @param {AbstractList[]} abstractLists
 * @returns {Omit<ListHandlerInstance, "currentSfdt">}
 */
export function $populateListHandler(
  listItems,
  lists = [],
  abstractLists = []
) {
  for (let index = 0; index < listItems.length; index++) {
    const listItem = listItems[index];

    const listLevelNumber = listItem.level; //2
    const previousListItem = listItems[index - 1]; //last item
    const currentListId = listItem?.listId; //current list id (5)
    const abstractList = getAbstractListByListId(
      lists,
      abstractLists,
      listItem?.listId
    );

    const abstractListExists = !!abstractList;

    // List of reversed list items until the current list item.
    const reversedListItems = listItems.slice(0, index).reverse();

    let listItemValue = abstractList?.levels?.at(listItem?.level)?.startAt ?? 1; // Default value of list is 1.
    let parentId = "";
    let allowListItemValueUpdate = true;

    // TODO: Check this code, there's probably something wrong with it.
    if (previousListItem && previousListItem.listId === currentListId) {
      //Calculate list item value
      //if there's a list before this one
      //and it's same list
      let previousListItemAtSameLevel;
      for (const item of reversedListItems) {
        if (item.listId === currentListId) {
          if (item.level === listLevelNumber && allowListItemValueUpdate) {
            previousListItemAtSameLevel = item;
            allowListItemValueUpdate = false;
          } else if (item.level <= listLevelNumber - 1) {
            if (item.level === listLevelNumber - 1) {
              parentId = item.id;
            }
            break;
          }
        }
        continue;
      }
      const continueNumbering = true; //unsure how to know if should continue or not
      if (previousListItemAtSameLevel && continueNumbering) {
        //and should continue numbering
        listItemValue = previousListItemAtSameLevel.value + 1;
      }
      //else val it's 1
    } else if (abstractListExists) {
      //if there's no list before or it's not same list and
      //it's not same list
      //but list exists somewhere
      let previousListItemAtSameLevel;
      for (const item of reversedListItems) {
        if (item.listId === currentListId) {
          if (item.level === listLevelNumber && allowListItemValueUpdate) {
            previousListItemAtSameLevel = item;
            allowListItemValueUpdate = false;
          } else if (item.level <= listLevelNumber - 1) {
            if (item.level === listLevelNumber - 1) {
              parentId = item.id;
            }
            break;
          }
        }
        continue;
      }
      if (previousListItemAtSameLevel) {
        listItemValue = previousListItemAtSameLevel.value + 1;
      }
      //else val it's 1
    } else {
      //it doesn't exist so val it's 1
    }

    listItem.value = listItemValue;
    listItem.parentId = parentId;
  }

  const result = {
    lists: abstractLists,
    root: listItems,
  };

  return result;
}
